import { FC, useEffect } from "react";
import { css } from "@emotion/css";
import { ResizableDrawer } from "@rubicon/antd-components";
import { DealDetailsDrawerContent } from "@app/features/deals/DealDetailsDrawer/DealDetailsDrawerContent";
import { useDealDetailsDrawer } from "@app/features/deals/DealDetailsDrawer/useDealDetailsDrawer";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "@app/core/routing";
import { convertSecondaryViewFromURL } from "./convertSecondaryView";
import { DEAL_DETAILS_DRAWER_LOCATION } from "../constants";
import { useDealDetailsDrawerWidth } from "./useDealDetailsDrawerWidth";

interface Params {
    dealId: string;
    secondaryView: string;
}

export const DealDetailsDrawer: FC = () => {
    const {
        isDealDetailsDrawerOpen,
        closeDealDetailsDrawer,
        openDealDetailsDrawer: openDrawer,
    } = useDealDetailsDrawer();

    const location = useLocation();
    const { dealId, secondaryView } = useParams<Params>();
    const drawerWidth = useDealDetailsDrawerWidth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (
            !!Number(dealId) &&
            !location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.EDIT) &&
            !location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.COPY)
        ) {
            if (secondaryView) {
                const secondaryViewConverted = convertSecondaryViewFromURL(secondaryView);
                openDrawer(
                    Number(dealId),
                    secondaryViewConverted,
                    location.pathname.includes(DEAL_DETAILS_DRAWER_LOCATION.DETAILS_DRAWER)
                );
                return;
            }
            openDrawer(Number(dealId));
            return;
        }

        !dealId && closeDealDetailsDrawer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealId, secondaryView]);

    return (
        <div data-sdet="deal-details-drawer">
            <ResizableDrawer
                open={isDealDetailsDrawerOpen}
                placement="right"
                width={drawerWidth}
                closable={false}
                onClose={closeDealDetailsDrawer}
                className={css`
                    .ant-drawer-body {
                        padding: 0;
                    }
                `}
            >
                <Switch>
                    <Route
                        exact
                        path={[
                            ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER,
                            ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW,
                            ROUTES.SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW,
                            ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER,
                            ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW,
                            ROUTES.SEAT_DEALS_SECONDARY_VIEW,
                            ROUTES.SEAT_DEAL_DETAILS,
                        ]}
                    >
                        <DealDetailsDrawerContent />
                    </Route>
                </Switch>
            </ResizableDrawer>
        </div>
    );
};
