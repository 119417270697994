import moment, { Moment } from "moment-timezone";
import { consoleApi, LIVE_EVENTS_LIST_TAG, LIVE_EVENTS_SINGLE_EVENT_TAG } from "./console";
import { SimpleTimeZone } from "./timezones";
import { toQueryString } from "./utils";
import { COLUMN_KEY } from "@app/features/inventory/InventoryLiveEvents/InventoryLiveEventsTable/useInventoryLiveEventsTable";

interface Identifiable {
    id: number;
}

interface LiveEventDTO {
    id: number;
    name: string;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: string;
    };
    startDate: string;
    endDate: string;
    timeZone: SimpleTimeZone;
    estimatedImpressionOpportunities: number;
    estimatedPeakConcurrentUsers: number;
    estimatedAverageConcurrentUsers: number;
    estimatedAdBreakCount: number;
    estimatedTotalAvailableAdMinutes: number;
}

type PaginatedLiveEvent<T> = {
    liveEvents: T[];
    maxResults: number;
    page: number;
    totalResults: number;
};

export type LiveEvent = Omit<LiveEventDTO, "startDate" | "endDate"> & {
    startDate: Moment;
    endDate: Moment;
};

export type LiveEventSubmitPayload = Omit<LiveEventDTO, "id" | "seat" | "timeZone"> & {
    seat: Identifiable;
    timeZone: Identifiable;
};

interface CreateLiveEventParams {
    seatId: number;
    body: LiveEventSubmitPayload;
}

interface CreateLiveEventResponse {
    creationDate: string;
    updatedDate: string;
}

interface UpdateLiveEventParams {
    eventId: number;
    body: LiveEventSubmitPayload & Pick<LiveEventDTO, "id">;
}

type OrderByColumnsKey = keyof Omit<typeof COLUMN_KEY, "ACTIONS">;
export type InventoryLiveEventsOrderByColumns = (typeof COLUMN_KEY)[OrderByColumnsKey];

interface GetLiveEventsMatrixParams {
    page: number;
    max: number;
    keyword?: string;
    orderBy?: InventoryLiveEventsOrderByColumns;
    isAscending?: boolean;
}

const mapLiveEventDTOToLiveEvent = (dto: LiveEventDTO): LiveEvent => ({
    ...dto,
    startDate: moment(dto.startDate).tz(dto.timeZone.code),
    endDate: moment(dto.endDate).tz(dto.timeZone.code),
});

export const liveEventsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getLiveEvents: builder.query<PaginatedLiveEvent<LiveEvent>, GetLiveEventsMatrixParams & { seatId: number }>({
            query: ({ seatId, ...params }) => `/seats/${seatId}/liveEvents;${toQueryString(params)}`,
            providesTags: () => [LIVE_EVENTS_LIST_TAG],
            transformResponse: (response: PaginatedLiveEvent<LiveEventDTO>) => {
                return {
                    ...response,
                    liveEvents: (response.liveEvents || []).map((dto) => mapLiveEventDTOToLiveEvent(dto)),
                };
            },
        }),
        getFutureLiveEvents: builder.query<
            PaginatedLiveEvent<LiveEvent>,
            GetLiveEventsMatrixParams & { seatId: number }
        >({
            query: ({ seatId, ...params }) => `/seats/${seatId}/liveEvents/picklist;${toQueryString(params)}`,
            providesTags: () => [LIVE_EVENTS_LIST_TAG],
            transformResponse: (response: PaginatedLiveEvent<LiveEventDTO>) => {
                return {
                    ...response,
                    liveEvents: (response.liveEvents || []).map((dto) => mapLiveEventDTOToLiveEvent(dto)),
                };
            },
        }),
        getLiveEventById: builder.query<LiveEvent, number>({
            query: (liveEventId) => `/liveEvents/${liveEventId}`,
            providesTags: (_, __, liveEventId) => [{ type: LIVE_EVENTS_SINGLE_EVENT_TAG, id: liveEventId }],
            transformResponse: (response: LiveEventDTO) => mapLiveEventDTOToLiveEvent(response),
        }),
        createLiveEvent: builder.mutation<CreateLiveEventResponse, CreateLiveEventParams>({
            query: (arg: CreateLiveEventParams) => {
                const { seatId, body } = arg;
                return {
                    url: `seats/${seatId}/liveEvent`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [LIVE_EVENTS_LIST_TAG]),
        }),
        updateLiveEvent: builder.mutation<CreateLiveEventResponse, UpdateLiveEventParams>({
            query: (liveEvent: UpdateLiveEventParams) => {
                const { eventId, body } = liveEvent;
                return {
                    url: `liveEvents/${eventId}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [LIVE_EVENTS_LIST_TAG]),
        }),
        deleteLiveEvent: builder.mutation<void, number>({
            query: (liveEventId) => ({
                url: `liveEvents/${liveEventId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err, liveEventId) =>
                err ? [] : [LIVE_EVENTS_LIST_TAG, { type: LIVE_EVENTS_SINGLE_EVENT_TAG, id: liveEventId }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetLiveEventsQuery,
    useGetFutureLiveEventsQuery,
    useGetLiveEventByIdQuery,
    useCreateLiveEventMutation,
    useUpdateLiveEventMutation,
    useDeleteLiveEventMutation,
} = liveEventsApi;
