import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Col, Row, Tag, Typography } from "antd";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { formatDateStringFromApi } from "@app/core/utils";
import { AdSourceTypeIds } from "@app/features/seatAdSources/constants";
import { useSeatAuthContext } from "@app/core/auth";
import { format, formatNumber } from "@rubicon/utils";

interface Props {
    adSource: AdSource;
}
const { Paragraph, Text } = Typography;

export const SeatAdSourcesDeliveryDetails: FC<Props> = ({ adSource }) => {
    const { context } = useSeatAuthContext();
    const adSourceTypeId = adSource.type.id;

    if (adSourceTypeId === AdSourceTypeIds.PRE_BID_DEALS) {
        return null;
    }

    const isDurationFieldsVisible = adSourceTypeId !== AdSourceTypeIds.TREMOR_NETWORK;

    const isTotalImpressionsVisible = [
        AdSourceTypeIds.PROGRAMMATIC_GUARANTEED,
        AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED,
        AdSourceTypeIds.CLIENT_SIDE_TAG_GUARANTEED,
        AdSourceTypeIds.SERVER_SIDE_TAG_GUARANTEED,
    ].includes(adSourceTypeId);

    const isDistributionGroupsFieldVisible =
        context?.distributionGroupsEnabled &&
        [AdSourceTypeIds.PROGRAMMATIC_GUARANTEED, AdSourceTypeIds.AUCTION_PRICE, AdSourceTypeIds.FIXED_PRICE].includes(
            adSourceTypeId
        );

    return (
        <>
            <Row>
                <Col sm={8}>
                    <Text strong>Start Date</Text>
                    <Paragraph>
                        {formatDateStringFromApi(
                            adSource?.bookingStartDate,
                            adSource?.timeZone?.code,
                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                        )}
                    </Paragraph>
                </Col>
                <Col sm={8}>
                    <Text strong>End Date</Text>
                    <Paragraph>
                        {adSource?.bookingEndDate !== null
                            ? formatDateStringFromApi(
                                  adSource?.bookingEndDate,
                                  adSource?.timeZone?.code,
                                  MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                              )
                            : format.constants.NOT_SPECIFIED}
                    </Paragraph>
                </Col>
                <Col sm={8}>
                    <Text strong>Time Zone</Text>
                    <Paragraph>
                        {format.asSelf(adSource?.timeZone?.name)} ({format.asSelf(adSource?.timeZone?.code)})
                    </Paragraph>
                </Col>
            </Row>
            {isTotalImpressionsVisible && (
                <Row>
                    <Col sm={8}>
                        <Text strong>Total Impressions</Text>
                        <Paragraph>{formatNumber.asNumber(adSource?.bookingVolume)}</Paragraph>
                    </Col>
                </Row>
            )}
            {isDurationFieldsVisible && (
                <Row>
                    <Col sm={8}>
                        <Text strong>Min Duration</Text>
                        <Paragraph>{format.asSelf(adSource?.adSourceMinDuration)}</Paragraph>
                    </Col>
                    <Col sm={8}>
                        <Text strong>Max Duration</Text>
                        <Paragraph>{format.asSelf(adSource?.adSourceMaxDuration)}</Paragraph>
                    </Col>
                </Row>
            )}
            {isDistributionGroupsFieldVisible && (
                <Row>
                    <Col sm={8}>
                        <Text strong>Inventory Distribution Groups</Text>
                        <Paragraph>
                            {adSource?.inventoryDistributionGroups && adSource?.inventoryDistributionGroups.length > 0
                                ? adSource?.inventoryDistributionGroups.map((label) => (
                                      <Tag key={label.labelValue?.id}>
                                          {label?.labelValue?.label?.key}:
                                          {adSourceTypeId === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED
                                              ? label.cap
                                              : label.throttle}
                                      </Tag>
                                  ))
                                : format.constants.DEFAULT_DASHES}
                        </Paragraph>
                    </Col>
                </Row>
            )}
        </>
    );
};
