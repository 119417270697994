import { Dashes } from "@app/core/components";
import { LabelValue } from "@app/core/services";
import { WATERFALL_PRE_FILTER_OPTIONS } from "@app/features/deals/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";

interface Props {
    strictMode: boolean;
    labelValues: LabelValue[];
}
export const AdSourceLabel: FC<Props> = ({ labelValues, strictMode }) => {
    const [preFilterMode] = WATERFALL_PRE_FILTER_OPTIONS.filter(({ value }) => value === strictMode);
    return (
        <Row>
            <Col span={24}>
                <Form.Item label="Waterfall Pre-Filter Mode">{preFilterMode.label}</Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Labels">
                    {labelValues?.length ? (
                        labelValues.map(({ label, value }) => (
                            <Tag key={`${label.key}${value}`}>
                                {label.key}: {value}
                            </Tag>
                        ))
                    ) : (
                        <Dashes />
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
};
