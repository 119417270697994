import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import conf from "@app/core/conf";

export const AD_RESPONSE_PRICE_OVERRIDES_TAG = "AdResponsePriceOverrides";
export const AD_RESPONSE_PRICE_OVERRIDES_TAG_BY_ID = "AdResponsePriceOverridesById";
export const AD_SOURCE_AUCTION_TYPES_TAG = "AdSourceAuctionTypes";
export const AD_SOURCE_CORE_INDEX_TIME = "AdSourceCoreIndexTime";
export const AD_SOURCE_FLOOR_TYPES_TAG = "AdSourceFloorTypes";
export const AD_SOURCE_GRANTS_TAG = "AdSourceGrants";
export const AD_SOURCE_IAB_CATEGORY_BLOCK_MODES_TAG = "AdSourceIabCategoryBlockMode";
export const AD_SOURCE_IAB_CATEGORY_TAG = "AdSourceIabCategory";
export const AD_SOURCE_PACING_PERIODS_TAG = "AdSourcePacingPeriods";
export const AD_SOURCE_PACING_TAG = "AdSourcePacingTag";
export const AD_SOURCE_PACING_TYPES_TAG = "AdSourcePacingTypes";
export const AD_SOURCE_PRICE_MODELS_TAG = "AdSourcePriceModels";
export const AD_SOURCE_PRIORITIES_TAG = "AdSourcePriorities";
export const AD_SOURCE_RESET_RATES_TAG = "AdSourceResetRates";
export const AD_SOURCE_TAG = "AdSource";
export const AD_SOURCE_TARGETING_INDEX_TIME = "AdSourceTargetingIndexTime";
export const AD_SOURCE_TIER_RESET_MODES_TAG = "AdSourceResetModes";
export const AD_SOURCE_TYPES_COUNTS = "AdSourceTypesCounts";
export const AD_SOURCES_ADVERTISER_BLOCKING_MODES_TAG = "AdSourcesAdvertiserBlockingModes";
export const AD_SOURCES_INDUSTRY_BLOCKING_MODES_TAG = "AdSourcesIndustryBlockingModes";
export const AD_SOURCES_TYPE_TAG = "AdSourcesType";
export const AD_UNIT = "AdUnit";
export const AD_UNIT_FLOORS_TAG = "AdUnitFloors";
export const AD_UNIT_PLACEMENTS_TAG = "AdUnitPlacements";
export const AD_UNIT_STATUS_TAG = "AdUnitStatus";
export const AD_UNIT_TYPES_TAG = "AdUnitTypes";
export const AD_UNIT_WAIT_TIMES_TAG = "AdUnitWaitTimes";
export const AD_UNITS_AD_POD_FILL_MODES_TAG = "AdUnitsAdPodFillModes";
export const AD_UNITS_ALLOWED_PLAYBACK_METHODS_TAG = "AdUnitsAllowedPlaybackMethods";
export const AD_UNITS_API_FRAMEWORK_TAG = "AdUnitsApiFramework";
export const AD_UNITS_CREATIVE_ATTRIBUTES_TAG = "AdUnitsCreativeAttributesTag";
export const AD_UNITS_DELIVERY_METHODS = "AdUnitsDeliveryMethods";
export const AD_UNITS_INHERITED_PIXELS_TAG = "AdUnitsInheritedPixels";
export const AD_UNITS_LINEARITIES_TAG = "AdUnitsLinearities";
export const AD_UNITS_MIME_TAG = "AdUnitsMime";
export const AD_UNITS_MULTIPLICITY_TAG = "AdUnitsMultiplicity";
export const AD_UNITS_PLAYLIST_DEFINITION_MODES_TAG = "PlaylistDefinitionMode";
export const AD_UNITS_POD_ENFORCEMENTS_TAG = "AdUnitsPodEnforcements";
export const AD_UNITS_SUPPORTED_PROTOCOLS_TAG = "AdUnitsApiSupportedProtocols";
export const APP_STORE_DATA_TAG = "appStoreData";
export const APP_STORE_TAG = "appStore";
export const AUDIENCE_TAG = "Audience";
export const AUDIENCES_TAG = "Audiences";
export const BRAND_CHANNEL_TAG = "BrandChannelTag";
export const BRAND_FLOORS_TAG = "BrandFloors";
export const BRAND_INHERITED_PIXELS_TAG = "BrandInheritedPixels";
export const BRAND_TAG = "Brand";
export const BRANDS_SAFETY_TAG = "BrandsSafetyTag";
export const BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG = "BrandsSafetyAdvertiserDomainsTag";
export const BRANDS_SAFETY_ADOMAIN_TAG = "BrandsSafetyAdvertiserDomainTag";
export const BRANDS_SAFETY_ADVERTISER_DOMAIN_TYPE_TAG = "BrandsSafetyAdvertiserDomainTypeTag";
export const BRANDS_SAFETY_TRANSPARENCY_DEFAULT_TAG = "BrandsSafetyDefaultTransparencyTag";
export const ADVANCED_BRANDS_SAFETY_TAG = "AdvancedBrandsSafetyTag";
export const BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG = "BrandsSafetySequesteredDomainsTag";
export const BRANDS_SUPPLY_TAG = "BrandsSupplyTag";
export const BULK_OPERATION_EXECUTIONS_TAG = "BulkOperationExecutionsTag";
export const BUYER_DEALS_PRICING_TYPES_TAG = "BuyerDealsPricingTypes";
export const BUYER_DEALS_TRANSACTION_TYPES_TAG = "BuyerDealsTransactionTypes";
export const BUYER_SEAT_LISTS_TAG = "BuyerSeatLists";
export const BUYER_SEAT_LIST_ITEM_TAG = "BuyerSeatListItem";
export const BUYER_STATUS_TAG = "BuyerStatus";
export const BUYER_TAG = "Buyer";
export const CHANGELOG_TAG = "Changelog";
export const CURRENCIES_CONVERSIONS_BY_CODE_TAG = "CurrenciesConversionsByCode";
export const CURRENCIES_CONVERSIONS_TAG = "CurrenciesConversions";
export const CURRENCIES_TAG = "Currencies";
export const CURATOR_MARKETPLACES_TAG = "CuratorMarketplaces";
export const DEAL_AD_SOURCE_TAG = "DealAdSource";
export const DEAL_HISTORICAL_CHART_AD_SOURCE_TAG = "DealHistoricalChartAdSource";
export const DEAL_HISTORICAL_CHART_DATE_RANGE_TAG = "DealHistoricalChartDateRange";
export const DEAL_ORIGINS_TAG = "DealOrigins";
export const DEAL_SOURCE_TYPES_TAG = "DealSourceTypes";
export const DEAL_STATUS_TAG = "DealStatus";
export const DEAL_TAG = "Deal";
export const DISTRIBUTION_GROUP_LABELS_TAG = "DistributionGroupLabels";
export const DMPS_TAG = "Dmps";
export const ENCRYPTION_KEYS_TAG = "EncryptionKeys";
export const ENFORCEMENTS_TAG = "Enforcements";
export const ENRICHMENT_COST_MODELS_TAG = "EnrichmentCostModels";
export const EXTENDED_IDS_TAG = "ExtendedIds";
export const EXTERNAL_CODE_NAMESPACES_TAG = "ExternalCodeNamespaces";
export const EXTERNAL_DATA_STREAMS_TAG = "ExternalDataStreams";
export const FINANCE_DEFAULTS_TAG = "FinanceDefaults";
export const FLOOR_TAG = "Floor";
export const FREQ_CAPPING_TYPES_TAG = "Enforcements";
export const GLOBALS_LABELS_TAG = "GlobalsLabels";
export const HELP_ARTICLE_TAG = "HelpArticle";
export const HELP_KNOWLEDGE_OWL_AUTH_TAG = "HelpKnowledgeOwlAuth";
export const IAB_CATEGORIES_TAG = "IabCategories";
export const LABEL_DETAILS_TAG = "LabelDetails";
export const LABEL_VALUES_TAG = "LabelValues";
export const LIBRARY_CREATIVES_TAG = "LibraryCreatives";
export const LIVE_EVENTS_LIST_TAG = "LiveEventsList";
export const LIVE_EVENTS_SINGLE_EVENT_TAG = "LiveEvents";
export const MAPPING_EXCEPTIONS_TAG = "MappingExceptions";
export const MARKETPLACES_TAG = "Marketplaces";
export const NETWORK_ENDPOINTS_AWS_REGIONS_TAG = "NetworkEndpointsAwsRegions";
export const OPTIMIZER_QUERIES_RESULTS_TAG = "SupplyFloorsOptimizerQueriesResults";
export const OPTIMIZER_QUERIES_TAG = "SupplyFloorsOptimizerQueries";
export const OPTIMIZER_TAG = "SupplyFloorsOptimizer";
export const PBS_BIDDER_CONFIGS_TAG = "PbsBidderConfigs";
export const PBS_SERVERS_TAG = "pbsServers";
export const PLATFORM_NOTIFICATIONS_ALL_TAG = "PlatformNotificationsAll";
export const PLATFORM_NOTIFICATIONS_COUNT_TAG = "PlatformNotificationsCount";
export const PLATFORM_NOTIFICATIONS_TAG = "PlatformNotifications";
export const PRICE_OVERRIDES_TAG = "PriceOverridesTag";
export const PRICE_OVERRIDE_BY_ID_TAG = "PriceOverrideByIdTag";
export const PRICE_OVERRIDE_TYPES = "PriceOverrideTypesTag";
export const PRIVATE_LABELS_TAG = "PrivateLabelsTag";
export const PUBLISHER_BRANDS_TAG = "PublisheBrandsTag";
export const PUBLISHER_FLOORS_TAG = "PublisherFloors";
export const PUBLISHER_INHERITED_PIXELS_TAG = "PublisherInheritedPixels";
export const PUBLISHER_INHERITED_LABELS_TAG = "PublisherInheritedLabels";
export const BRAND_INHERITED_LABELS_TAG = "BrandInheritedLabels";
export const SUPPLY_INHERITED_LABELS_TAG = "SupplyInheritedLabels";
export const AD_UNIT_INHERITED_LABELS_TAG = "AdUnitInheritedLabels";
export const RECYCLE_BIN_TAG = "RecycleBin";
export const REPORTS_CUSTOM_REPORTS_TAG = "ReportsCustomReports";
export const REPORTS_DASHBOARD_SUPPLY_TAG = "ReportsDashboardSupply";
export const REPORTS_RECENT_REPORTS_TAG = "ReportsRecentReports";
export const SEAT_ACCESS_TAG = "SeatAccess";
export const SEAT_AD_SOURCES_PACING_TAG = "SeatAdSourcesPacingTag";
export const SEAT_AD_SOURCES_PICKLIST_TAG = "SeatAdSourcesPicklist";
export const SEAT_AD_SOURCES_STATUSES_TAG = "SeatAdSourcesStatuses";
export const SEAT_AD_SOURCES_TAG = "SeatAdSourcesTag";
export const SEAT_AD_SOURCES_TYPE_TAG = "SeatAdSourcesType";
export const SEAT_ADS_TEXT_TAG = "SeatAdsText";
export const SEAT_ADVANCED_FLOOR_TAG = "SeatAdvancedFloor";
export const SEAT_ADVANCED_FLOORS_LIST_TAG = "SeatAdvancedFloorsList";
export const SEAT_ADVERTISER_DETAILS_TAG = "SeatAdvertiserDetails";
export const SEAT_ADVERTISER_DOMAIN_FILTER_LIST_DEFS_TAG = "SeatAdvertiserDomainFilterListDefs";
export const SEAT_ADVERTISERS_TAG = "SeatAdvertisers";
export const SEAT_ALL_DEALS_PICK_LIST_TAG = "SeatAllPicklistDeals";
export const SEAT_ALL_DEALS_TAG = "SeatAllDeals";
export const SEAT_AD_UNITS_TAG = "SeatAdUnitsTag";
export const SEAT_CTV_NEXT_AD_UNITS_TAG = "SeatCtvNextAdUnitsTag";
export const SEAT_CTV_NEXT_DEMAND_DEALS_TAG = "SeatCtvNextDemandDealsTag";
export const SEAT_CTV_NEXT_BUYER_DEALS_TAG = "SeatCtvNextBuyerDealsTag";
export const SEAT_ASSIGNEES_TAG = "SeatAssignees";
export const SEAT_AVAILABLE_NETWORKS_TAG = "SeatAvailableNetworks";
export const SEAT_CONTACTS_TAG = "SeatContacts";
export const SEAT_CONTACT_BY_ID_TAG = "SeatContactById";
export const SEAT_CONTACT_TYPES_TAG = "SeatContactTypes";
export const SEAT_CONTENT_TRANSPARENCY_RULE_TAG = "SeatContentTransparencyRule";
export const SEAT_CONTENT_TRANSPARENCY_RULES_TAG = "SeatContentTransparencyRules";
export const SEAT_CONTENT_TRANSPARENCY_RULES_FIELDS_TAG = "SeatContentTransparencyRulesFields";
export const SEAT_DOMAINS_TAG = "SestDomains";
export const SEAT_FLOORS_LIST_TAG = "SeatFloorsList";
export const SEAT_INDUSTRIES_TAG = "SeatIndustries";
export const SEAT_INDUSTRIES_GLOBAL_TAG = "SeatIndustriesGlobal";
export const SEAT_INDUSTRY_DETAILS_TAG = "SeatIndustryDetails";
export const SEAT_LABEL_VALUES_TAG = "SeatLabelValues";
export const SEAT_LABELS_TAG = "SeatLabels";
export const SEAT_NUKE_STATUS = "SeatNukeStatus";
export const STRUCTURED_SEAT_NUKES = "StructuredSeatNukesStatus";
export const SEAT_PREFILTER_LABELS_TAG = "SeatPrefilterLabels";
export const SEAT_REUSABLE_TARGETING_BY_ID_TAG = "SeatReusableTargetingById";
export const SEAT_REUSABLE_TARGETING_REFERENCES_BY_ID_TAG = "SeatReusableTargetingReferencesById";
export const SEAT_REUSABLE_TARGETING_TAG = "SeatReusableTargeting";
export const SEAT_SELF_TAG = "SeatSelf";
export const SEAT_SEPARATION_GROUPS_TAG = "SeatSeparationGroups";
export const SEAT_SEPARATION_BY_ID_TAG = "SeatSeparationById";
export const SEAT_SUPPLY_BUNDLE_IDS_TAG = "SeatBundleIds";
export const SEAT_SUPPLY_DOMAINS_TAG = "SeatSupplyDomains";
export const SEAT_TAG_PARAMS_TAG = "TagParams";
export const SEAT_THIRD_PARTY_PIXEL_BY_ID_TAG = "SeatThirdPartyPixelsById";
export const SEAT_THIRD_PARTY_PIXELS_TAG = "SeatThirdPartyPixels";
export const SEAT_TRANSPARENCY_DEFAULTS_TAG = "SeatTransparencyDefaults";
export const SEAT_USERS_TAG = "SeatUsers";
export const SEAT_WATERFALL_PACING_TAG = "SeatWaterfallPacing";
export const SEATS_ACCOUNT_TYPES_TAG = "SeatsAccountTypes";
export const SEATS_ALLOWED_FMT_JSON_OPTIONS_TAG = "SeatsAllowedFmtJsonOptions";
export const SEATS_CLIENT_TYPES_TAG = "SeatsClientTypes";
export const SEATS_PICKLIST_TAG = "SeatsPicklist";
export const SEATS_SUPPORT_TYPES_TAG = "SeatsSupportTiers";
export const SSAI_TYPES_TAG = "AdUnitSsaiTypes";
export const SUPPLY = "Supply";
export const SUPPLY_AD_UNITS_TAG = "SupplyAdUnits";
export const SUPPLY_BUNDLE_ID_FILTER_LIST_ID_AND_NAME_TAG = "SupplyBundleIdFilterListIdAndName";
export const SUPPLY_DOMAIN_FILTER_LIST_ID_AND_NAME_TAG = "SupplyDomainFilterListIdAndName";
export const SUPPLY_FLOORS_TAG = "SupplyFloors";
export const SUPPLY_IFA_TYPES_TAG = "IfaTypes";
export const SUPPLY_INHERITED_PIXELS_TAG = "SupplyInheritedPixels";
export const SUPPLY_PAID_TYPES_TAG = "SupplyPaidTypes";
export const SUPPLY_RELATIONSHIPS_TYPES_TAG = "RelationshipsTypes";
export const SUPPLY_RESELL_TYPES_TAG = "ResellTypes";
export const SUPPLY_SEAT_TAG = "SupplySeat";
export const SUPPLY_TYPES_TAG = "SupplyTypes";
export const SUPPLY_VIDEO_QUALITY_TYPES_TAG = "VideoQualityTypes";
export const TIME_ZONES_TAG = "TimeZones";
export const UDE_LID_DISCOVERY_MODES_TAG = "UdeLidDiscoveryModes";
export const UNIQUES_COUNTS = "UniquesCounts";
export const USER_GROUPS = "UserGroups";
export const USER_PREFERENCES_TAG = "UserPreferences";
export const USERS_TAG = "Users";
export const VIEWABILITY_VENDORS_TAG = "ViewabilityVendors";

export const consoleApi = createApi({
    reducerPath: "console",
    tagTypes: [
        PUBLISHER_INHERITED_LABELS_TAG,
        BRAND_INHERITED_LABELS_TAG,
        SUPPLY_INHERITED_LABELS_TAG,
        AD_UNIT_INHERITED_LABELS_TAG,
        AD_RESPONSE_PRICE_OVERRIDES_TAG_BY_ID,
        AD_RESPONSE_PRICE_OVERRIDES_TAG,
        AD_SOURCE_AUCTION_TYPES_TAG,
        AD_SOURCE_CORE_INDEX_TIME,
        AD_SOURCE_FLOOR_TYPES_TAG,
        AD_SOURCE_GRANTS_TAG,
        AD_SOURCE_IAB_CATEGORY_BLOCK_MODES_TAG,
        AD_SOURCE_PACING_PERIODS_TAG,
        AD_SOURCE_PACING_TAG,
        AD_SOURCE_PACING_TYPES_TAG,
        AD_SOURCE_PRICE_MODELS_TAG,
        AD_SOURCE_PRIORITIES_TAG,
        AD_SOURCE_RESET_RATES_TAG,
        AD_SOURCE_TAG,
        AD_SOURCE_TARGETING_INDEX_TIME,
        AD_SOURCE_TIER_RESET_MODES_TAG,
        AD_SOURCE_TYPES_COUNTS,
        AD_SOURCES_ADVERTISER_BLOCKING_MODES_TAG,
        AD_SOURCES_INDUSTRY_BLOCKING_MODES_TAG,
        AD_SOURCES_TYPE_TAG,
        AD_UNIT_FLOORS_TAG,
        AD_UNIT_PLACEMENTS_TAG,
        AD_UNIT_STATUS_TAG,
        AD_UNIT_TYPES_TAG,
        AD_UNIT_WAIT_TIMES_TAG,
        AD_UNIT,
        AD_UNITS_AD_POD_FILL_MODES_TAG,
        AD_UNITS_ALLOWED_PLAYBACK_METHODS_TAG,
        AD_UNITS_API_FRAMEWORK_TAG,
        AD_UNITS_CREATIVE_ATTRIBUTES_TAG,
        AD_UNITS_DELIVERY_METHODS,
        AD_UNITS_INHERITED_PIXELS_TAG,
        AD_UNITS_LINEARITIES_TAG,
        AD_UNITS_MIME_TAG,
        AD_UNITS_MULTIPLICITY_TAG,
        AD_UNITS_PLAYLIST_DEFINITION_MODES_TAG,
        AD_UNITS_POD_ENFORCEMENTS_TAG,
        AD_UNITS_SUPPORTED_PROTOCOLS_TAG,
        APP_STORE_DATA_TAG,
        APP_STORE_TAG,
        AUDIENCE_TAG,
        AUDIENCES_TAG,
        BRAND_CHANNEL_TAG,
        BRAND_FLOORS_TAG,
        BRAND_INHERITED_PIXELS_TAG,
        BRAND_TAG,
        BRANDS_SAFETY_TAG,
        ADVANCED_BRANDS_SAFETY_TAG,
        BRANDS_SUPPLY_TAG,
        BRANDS_SAFETY_ADVERTISER_DOMAINS_TAG,
        BRANDS_SAFETY_ADOMAIN_TAG,
        BRANDS_SAFETY_ADVERTISER_DOMAIN_TYPE_TAG,
        BRANDS_SAFETY_TRANSPARENCY_DEFAULT_TAG,
        BRANDS_SAFETY_SEQUESTERED_DOMAINS_TAG,
        BULK_OPERATION_EXECUTIONS_TAG,
        BUYER_DEALS_PRICING_TYPES_TAG,
        BUYER_DEALS_TRANSACTION_TYPES_TAG,
        BUYER_SEAT_LISTS_TAG,
        BUYER_SEAT_LIST_ITEM_TAG,
        BUYER_STATUS_TAG,
        BUYER_TAG,
        CHANGELOG_TAG,
        CURRENCIES_CONVERSIONS_BY_CODE_TAG,
        CURRENCIES_CONVERSIONS_TAG,
        CURRENCIES_TAG,
        CURATOR_MARKETPLACES_TAG,
        DEAL_AD_SOURCE_TAG,
        DEAL_HISTORICAL_CHART_AD_SOURCE_TAG,
        DEAL_HISTORICAL_CHART_DATE_RANGE_TAG,
        DEAL_ORIGINS_TAG,
        DEAL_SOURCE_TYPES_TAG,
        DEAL_STATUS_TAG,
        DEAL_TAG,
        DISTRIBUTION_GROUP_LABELS_TAG,
        DMPS_TAG,
        ENCRYPTION_KEYS_TAG,
        ENFORCEMENTS_TAG,
        ENRICHMENT_COST_MODELS_TAG,
        EXTENDED_IDS_TAG,
        EXTERNAL_CODE_NAMESPACES_TAG,
        EXTERNAL_DATA_STREAMS_TAG,
        FINANCE_DEFAULTS_TAG,
        FLOOR_TAG,
        FREQ_CAPPING_TYPES_TAG,
        GLOBALS_LABELS_TAG,
        HELP_ARTICLE_TAG,
        HELP_KNOWLEDGE_OWL_AUTH_TAG,
        IAB_CATEGORIES_TAG,
        LABEL_DETAILS_TAG,
        LABEL_VALUES_TAG,
        LIBRARY_CREATIVES_TAG,
        LIVE_EVENTS_LIST_TAG,
        LIVE_EVENTS_SINGLE_EVENT_TAG,
        MAPPING_EXCEPTIONS_TAG,
        MARKETPLACES_TAG,
        NETWORK_ENDPOINTS_AWS_REGIONS_TAG,
        OPTIMIZER_QUERIES_RESULTS_TAG,
        OPTIMIZER_QUERIES_TAG,
        OPTIMIZER_TAG,
        PBS_BIDDER_CONFIGS_TAG,
        PBS_SERVERS_TAG,
        PLATFORM_NOTIFICATIONS_ALL_TAG,
        PLATFORM_NOTIFICATIONS_COUNT_TAG,
        PLATFORM_NOTIFICATIONS_TAG,
        PRICE_OVERRIDES_TAG,
        PRICE_OVERRIDE_BY_ID_TAG,
        PRICE_OVERRIDE_TYPES,
        PRIVATE_LABELS_TAG,
        PUBLISHER_BRANDS_TAG,
        PUBLISHER_FLOORS_TAG,
        PUBLISHER_INHERITED_PIXELS_TAG,
        RECYCLE_BIN_TAG,
        REPORTS_CUSTOM_REPORTS_TAG,
        REPORTS_DASHBOARD_SUPPLY_TAG,
        REPORTS_RECENT_REPORTS_TAG,
        SEAT_ACCESS_TAG,
        SEAT_ADS_TEXT_TAG,
        SEAT_AD_SOURCES_PACING_TAG,
        SEAT_AD_SOURCES_PICKLIST_TAG,
        SEAT_AD_SOURCES_STATUSES_TAG,
        SEAT_AD_SOURCES_TAG,
        SEAT_AD_SOURCES_TYPE_TAG,
        SEAT_ADVANCED_FLOOR_TAG,
        SEAT_ADVANCED_FLOORS_LIST_TAG,
        SEAT_ADVERTISER_DETAILS_TAG,
        SEAT_ADVERTISER_DOMAIN_FILTER_LIST_DEFS_TAG,
        SEAT_ADVERTISERS_TAG,
        SEAT_ALL_DEALS_PICK_LIST_TAG,
        SEAT_ALL_DEALS_TAG,
        SEAT_AD_UNITS_TAG,
        SEAT_CTV_NEXT_DEMAND_DEALS_TAG,
        SEAT_CTV_NEXT_AD_UNITS_TAG,
        SEAT_CTV_NEXT_BUYER_DEALS_TAG,
        SEAT_ASSIGNEES_TAG,
        SEAT_AVAILABLE_NETWORKS_TAG,
        SEAT_CONTACTS_TAG,
        SEAT_CONTACT_BY_ID_TAG,
        SEAT_CONTACT_TYPES_TAG,
        SEAT_CONTENT_TRANSPARENCY_RULE_TAG,
        SEAT_CONTENT_TRANSPARENCY_RULES_TAG,
        SEAT_CONTENT_TRANSPARENCY_RULES_FIELDS_TAG,
        SEAT_DOMAINS_TAG,
        SEAT_FLOORS_LIST_TAG,
        SEAT_INDUSTRIES_TAG,
        SEAT_INDUSTRIES_GLOBAL_TAG,
        SEAT_INDUSTRY_DETAILS_TAG,
        SEAT_LABEL_VALUES_TAG,
        SEAT_LABELS_TAG,
        SEAT_NUKE_STATUS,
        STRUCTURED_SEAT_NUKES,
        SEAT_PREFILTER_LABELS_TAG,
        SEAT_REUSABLE_TARGETING_BY_ID_TAG,
        SEAT_REUSABLE_TARGETING_REFERENCES_BY_ID_TAG,
        SEAT_REUSABLE_TARGETING_TAG,
        SEAT_SELF_TAG,
        SEAT_SEPARATION_GROUPS_TAG,
        SEAT_SEPARATION_BY_ID_TAG,
        SEAT_SUPPLY_BUNDLE_IDS_TAG,
        SEAT_SUPPLY_DOMAINS_TAG,
        SEAT_TAG_PARAMS_TAG,
        SEAT_THIRD_PARTY_PIXEL_BY_ID_TAG,
        SEAT_THIRD_PARTY_PIXELS_TAG,
        SEAT_TRANSPARENCY_DEFAULTS_TAG,
        SEAT_USERS_TAG,
        SEAT_WATERFALL_PACING_TAG,
        SEATS_ACCOUNT_TYPES_TAG,
        SEATS_ALLOWED_FMT_JSON_OPTIONS_TAG,
        SEATS_CLIENT_TYPES_TAG,
        SEATS_PICKLIST_TAG,
        SEATS_SUPPORT_TYPES_TAG,
        SSAI_TYPES_TAG,
        SUPPLY_AD_UNITS_TAG,
        SUPPLY_BUNDLE_ID_FILTER_LIST_ID_AND_NAME_TAG,
        SUPPLY_DOMAIN_FILTER_LIST_ID_AND_NAME_TAG,
        SUPPLY_FLOORS_TAG,
        SUPPLY_IFA_TYPES_TAG,
        SUPPLY_INHERITED_PIXELS_TAG,
        SUPPLY_PAID_TYPES_TAG,
        SUPPLY_RELATIONSHIPS_TYPES_TAG,
        SUPPLY_RESELL_TYPES_TAG,
        SUPPLY_SEAT_TAG,
        SUPPLY_TYPES_TAG,
        SUPPLY_VIDEO_QUALITY_TYPES_TAG,
        SUPPLY,
        TIME_ZONES_TAG,
        UDE_LID_DISCOVERY_MODES_TAG,
        UNIQUES_COUNTS,
        USER_GROUPS,
        USER_PREFERENCES_TAG,
        USERS_TAG,
        VIEWABILITY_VENDORS_TAG,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${conf.mctvPlatformApiRoot}/platform/resources/`,
        credentials: "include",
        prepareHeaders: (headers) => {
            return headers;
        },
    }),
    endpoints: () => ({}),
});
