import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { SorterResult } from "antd/lib/table/interface";
import {
    Currency,
    LiveStatsDeal,
    TimeZone,
    useGetCurrenciesQuery,
    useGetSeatAllDealsLiveStatsQuery,
    useGetTimeZonesQuery,
} from "@app/core/services";
import { getDealTableSort } from "@app/features/deals/helpers";
import { useDealHealthTableFilters } from "../DealHealthTableFilters/useDealHealthTableFilters";

// live stats polling interval in ms
const pollingInterval = 30000;

interface UseDealHealthTable {
    data:
        | {
              deals?: LiveStatsDeal[];
              totalResults?: number;
          }
        | undefined;
    handleChange: (_: unknown, __: unknown, sorter: SorterResult<TableItem>) => void;
    isFetching: boolean;
    seatId: string;
    timeZones: TimeZone[];
    currencies: Currency[];
}

export interface TableItem {
    key: number;
    name: ReactNode;
    priority: ReactNode;
    code: ReactNode;
    entityType: string;
    startDate: ReactNode;
    sortPacing: ReactNode;
    cpmRate: ReactNode;
    tries: ReactNode;
    impressions: ReactNode;
    netRevenue: ReactNode;
    useRate: ReactNode;
    statusName: ReactNode;
    actions: ReactNode;
}

export const useDealHealthTable = (): UseDealHealthTable => {
    const { seatId } = useParams<{ seatId: string }>();
    const { seatAllDealHealthParams, setDealHealthTableSort } = useDealHealthTableFilters();
    const { data: currencies = [] } = useGetCurrenciesQuery();
    const { data: timeZones = [] } = useGetTimeZonesQuery();
    const { data, isFetching } = useGetSeatAllDealsLiveStatsQuery(seatAllDealHealthParams, { pollingInterval });

    const dealHealthTableData = {
        deals: data?.data,
        maxResults: data?.responseMeta.paginationResponse.pageSize,
        page: data?.responseMeta.paginationResponse.currentPage,
        totalResults: data?.responseMeta.paginationResponse.totalCount,
    };

    const handleChange = (_a, _b, sort): void => {
        const { field, order } = sort;
        setDealHealthTableSort(getDealTableSort(field, order));
    };

    return {
        data: dealHealthTableData,
        handleChange,
        isFetching,
        seatId,
        timeZones,
        currencies,
    };
};
