import { useParams } from "react-router-dom";
import { LabelValue, useGetAdUnitInheritedLabelsQuery } from "@app/core/services";
import { useAdUnitFormInstance } from "@app/features/inventory/HierarchyForms/AdUnitForm/AdUnitFormInstance";

export interface UseAdUnitInheritedLabels {
    isInheritedLabelsShown: boolean;
    labels: LabelValue[];
}

export interface UseAdUnitInheritedLabelsProps {
    labelType: "seat" | "internal";
}

export const useAdUnitInheritedLabels = ({ labelType }: UseAdUnitInheritedLabelsProps): UseAdUnitInheritedLabels => {
    const { adUnitId } = useParams<{ adUnitId: string }>();
    const { mode } = useAdUnitFormInstance();
    const isEditMode = mode === "edit";

    const { data = [] } = useGetAdUnitInheritedLabelsQuery(
        { adUnitId: adUnitId, includeMine: false, labelType: labelType },
        { skip: !isEditMode || !adUnitId }
    );
    return {
        labels: data,
        isInheritedLabelsShown: isEditMode,
    };
};
