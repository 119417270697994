import { FC } from "react";
import { Col, Tag } from "antd";
import { AdSource } from "@app/core/services/console/adSources";
import { DetailsItem } from "../DetailsItem";
import { NONE } from "@app/core/components/constants";
import {
    completedLiveEvents,
    currentlyLiveEvents,
} from "../../SeatAdSourcesForm/AdSourcesSections/LiveEventsSection/utils";

interface Props {
    adSource: AdSource;
}

export const SeatAdSourcesLiveEventsDetails: FC<Props> = ({ adSource }) => {
    const liveEvents = currentlyLiveEvents(adSource?.liveEvents);
    const completedEvents = completedLiveEvents(adSource?.liveEvents);

    return (
        <Col>
            <DetailsItem label="Live Events">
                {adSource?.liveEvents.length > 0
                    ? liveEvents.map((event) => <Tag key={event.id}>{event.name}</Tag>)
                    : NONE}
            </DetailsItem>
            <DetailsItem label="Completed Events">
                {completedEvents.length > 0
                    ? completedEvents.map((event) => <Tag key={event.id}>{event.name}</Tag>)
                    : NONE}
            </DetailsItem>
        </Col>
    );
};
