import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useAppSelector } from "@app/core/store";
import { selectIsBeta } from "../deprecated/reducer";

export const DealHealthToolbar: FC = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { canEditSeat } = useUserAccess();
    const handleCreateRule = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_DEAL_CREATE(seatId));
    };
    const isBeta = useAppSelector(selectIsBeta);

    return (
        <PageHeader
            title={`Deal Health${isBeta ? " (BETA)" : ""}`}
            extra={
                canEditSeat && (
                    <Button type="primary" onClick={handleCreateRule} data-sdet="create">
                        Create
                    </Button>
                )
            }
        />
    );
};
