import { FC } from "react";
import { Card, Col, Row, Collapse } from "antd";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_NAME,
    AdSourceTypeIds,
    cardBodyStyle,
    GUTTER,
    mainStepSectionClassList,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { SeatAdSourcesType } from "@app/core/services";
import { useWatch } from "antd/lib/form/Form";
import { LiveEventFormItem } from "./LiveEventFormItem";
import { isDevOrQaEnv } from "@app/core/conf";

export const LiveEventsSection: FC = () => {
    const adSourceTypeId = useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name)?.id;

    if (adSourceTypeId !== AdSourceTypeIds.PROGRAMMATIC_GUARANTEED || !isDevOrQaEnv) return null;

    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.LIVE_EVENTS,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.LIVE_EVENTS]}
                            section={AD_SOURCE_SECTIONS.LIVE_EVENTS}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col span={24}>
                                    <LiveEventFormItem />
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
