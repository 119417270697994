import { SIDE_NAV_WIDTH } from "@app/core/components/constants";
import { Grid } from "antd";

export const useDealDetailsDrawerWidth = () => {
    const { md, lg } = Grid.useBreakpoint();

    if (lg) {
        return "70%";
    }

    if (md) {
        return "80%";
    }

    return `calc(100% - ${SIDE_NAV_WIDTH})`;
};
