import { SeatAdSourcesType } from "@app/core/services";
import { COL_SIZES, GUTTER } from "@app/features/deals/constants";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_SECTIONS_NAME,
    AdSourceTypeIds,
    cardBodyStyle,
    mainStepSectionClassList,
} from "@app/features/seatAdSources/constants";
import { Card, Col, Form, Row, Collapse } from "antd";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { ChooseConfigsMultiselect } from "./fields";

export const PbsBidderConfiguraionsSection = () => {
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;
    const isVisible = adSourceTypeId === AdSourceTypeIds.PRE_BID_AD_SOURCE;

    if (!isVisible) {
        return null;
    }
    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.PBS_BIDDER_CONFIGURATIONS,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS]}
                            section={AD_SOURCE_SECTIONS.PBS_BIDDER_CONFIGURATIONS}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES} md={16} lg={16} xl={16} xxl={16}>
                                    <ChooseConfigsMultiselect />
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
