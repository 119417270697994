import { AppSwitch } from "@app/core/routing";
import { AppVersionRefreshModal } from "@app/features/appVersionRefresh";
import { css } from "@emotion/css";
import { Layout } from "antd";
import { Pendo } from "../Pendo";
import { useWatchSeatPermissions } from "@app/core/auth/useWatchSeatPermissions";
import { useBuyerAuthContext, useSeatAuthContext } from "@app/core/auth";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createConnection, deleteConnection } from "@rubicon/iframe-communicator";
import { useCommunicatorSession } from "@rubicon/iframe-communicator/react";

export const IframeMain = () => {
    //INFO: Figure out Do we have buyer route here or only seat?
    useWatchSeatPermissions();
    const session = useCommunicatorSession();
    const location = useLocation();
    const { context: seatContext, session: seatSession } = useSeatAuthContext();
    const { context: buyerContext, session: buyerSession } = useBuyerAuthContext();

    useEffect(() => {
        session?.emitEvent("hashchange", { hash: window.location.hash });
    }, [location, session]);

    useEffect(() => {
        if (!window.document.referrer) {
            return;
        }

        const referrer = new URL(window.document.referrer);
        const session = createConnection(referrer.origin);

        if (session instanceof Error) {
            console.error("iframe-communicator error", session);
            return;
        }

        return () => {
            deleteConnection();
        };
    }, []);

    return (
        <Layout
            data-sdet="main"
            className={css`
                min-height: 100vh;
                overflow: hidden;
                width: 100%;
            `}
        >
            <Layout
                className={css`
                    position: relative;
                    min-height: 100vh;
                `}
            >
                <AppVersionRefreshModal />
                <AppSwitch />
            </Layout>
            <Pendo context={seatContext || buyerContext} session={seatContext ? seatSession : buyerSession} />
        </Layout>
    );
};
