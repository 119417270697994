import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_ITEMS } from "../../../constants";

export const LiveEventsFormItemName: FC = () => {
    return (
        <Form.Item
            required
            name={FORM_ITEMS.NAME.name}
            label={FORM_ITEMS.NAME.label}
            rules={[{ required: true, message: "Name is required" }]}
        >
            <Input placeholder="Enter Name..." />
        </Form.Item>
    );
};
