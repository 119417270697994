import { NONE } from "@app/core/components/constants";
import { LabelValue } from "@app/core/services";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { GUTTER, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { FORM_ITEM_OFFSET } from "../../constants";
import { InheritedLabels } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/Labels/InheritedLabels";

interface Props {
    id: number;
    labels?: LabelValue[];
    isInternalOnly?: boolean;
    internalLabelValues?: LabelValue[];
    distributionGroupsEnabled?: boolean;
    distributionGroupLabelValues?: LabelValue[];
}

const { Item } = Form;

export const Labels: FC<Props> = ({
    id,
    labels,
    internalLabelValues,
    distributionGroupLabelValues,
    isInternalOnly = false,
    distributionGroupsEnabled = false,
}) => {
    return (
        <div data-sdet="unit-labels-section">
            <Row gutter={GUTTER}>
                <Col>
                    <Item label="Seat Labels" className={FORM_ITEM_OFFSET}>
                        {labels?.length
                            ? labels?.map((label) => {
                                  return (
                                      <Tag key={`${label?.label?.key}-${label?.value}`}>
                                          {label?.label?.key}: {label?.value}
                                      </Tag>
                                  );
                              })
                            : NONE}
                    </Item>
                    <InheritedLabels id={id} labelType="seat" />
                </Col>
            </Row>
            {distributionGroupsEnabled && (
                <Row gutter={GUTTER}>
                    <Col>
                        <Item label="Distribution Group Labels" className={FORM_ITEM_OFFSET}>
                            {distributionGroupLabelValues?.length
                                ? distributionGroupLabelValues?.map((label) => {
                                      return (
                                          <Tag key={`${label?.label?.key}-${label?.value}`}>
                                              {label?.label?.key}: {label?.value}
                                          </Tag>
                                      );
                                  })
                                : NONE}
                        </Item>
                    </Col>
                </Row>
            )}
            <Row gutter={GUTTER}>
                <Col hidden={!isInternalOnly} {...TWO_COL_SIZES}>
                    <InternalOnly>
                        <Item label="Global Labels" className={FORM_ITEM_OFFSET}>
                            {internalLabelValues?.length
                                ? internalLabelValues?.map((label) => {
                                      return (
                                          <Tag key={`${label?.label?.key}-${label?.value}`}>
                                              {label?.label?.key}: {label?.value}
                                          </Tag>
                                      );
                                  })
                                : NONE}
                        </Item>
                        <InheritedLabels id={id} labelType="internal" />
                    </InternalOnly>
                </Col>
            </Row>
        </div>
    );
};
