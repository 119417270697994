import { useCallback, useEffect, useMemo, useState } from "react";
import { ManagedColumn } from "@app/core/components/ManageColumns/ManageColumns";

interface UseDealDetailsDrawer {
    isDrawerOpen: boolean;
    allColumns: ManagedColumn[];
    visibleColumns: ManagedColumn[];
    handleOpenManageColumnsDrawer: () => void;
    handleCloseManageColumnsDrawer: () => void;
    onChange: (newItems: ManagedColumn[]) => void;
}

export const MANAGE_COLUMNS_KEY_DEAL_HEALTH = "dealHealthColumns";
export const MANAGE_COLUMNS_KEY_DEAL_HEALTH_DEPRECATED = "dealHealthColumnsDeprecated";
export const MANAGE_COLUMNS_KEY_DEAL_MANAGEMENT = "dealManagementColumns";
export const MANAGE_COLUMNS_KEY_AD_SOURCES = "adSourcesColumns";
export const MANAGE_COLUMNS_KEY_INVENTORY_HELATH = "inventoryHealthColumns";

type columnType =
    | typeof MANAGE_COLUMNS_KEY_DEAL_HEALTH
    | typeof MANAGE_COLUMNS_KEY_DEAL_HEALTH_DEPRECATED
    | typeof MANAGE_COLUMNS_KEY_DEAL_MANAGEMENT
    | typeof MANAGE_COLUMNS_KEY_AD_SOURCES
    | typeof MANAGE_COLUMNS_KEY_INVENTORY_HELATH;

interface PersistedColumn {
    dataIndex: string;
    visible: boolean;
}

const managedColumnsToPersistedColumns = (managedColumns: ManagedColumn[]): PersistedColumn[] =>
    managedColumns.map(
        (col) =>
            ({
                dataIndex: col.dataIndex,
                visible: col.visible,
            } as PersistedColumn)
    );

const persistedColumnsToManagedColumns = (
    persistedColumns: PersistedColumn[],
    defaultColumns: ManagedColumn[]
): ManagedColumn[] =>
    persistedColumns.map((pc: PersistedColumn) => ({
        ...(defaultColumns.find((dc: ManagedColumn) => dc.dataIndex === pc.dataIndex) as ManagedColumn),
        visible: pc.visible,
    }));

export const getManagedColumnPreferences = (
    columnType: columnType,
    defaultColumns: ManagedColumn[]
): ManagedColumn[] => {
    const savedColsString = localStorage.getItem(columnType);
    if (savedColsString) {
        try {
            const savedColumns = JSON.parse(savedColsString);
            if (savedColumns.length !== defaultColumns.length) {
                // Revert to default column config if the number of entries don't match.
                // This handles the case where a developer adds or removes a column.
                // Otherwise, new columns remain hidden since the saved column config doesn't contain a reference.
                return defaultColumns;
            }
            return persistedColumnsToManagedColumns(savedColumns, defaultColumns);
        } catch (e) {
            console.error(e);
            return defaultColumns;
        }
    } else {
        return defaultColumns;
    }
};

export const saveManagedColumnPreferences = (columnType: columnType, columns: ManagedColumn[]): void => {
    try {
        localStorage.setItem(columnType, JSON.stringify(managedColumnsToPersistedColumns(columns)));
    } catch (e) {
        console.error(e);
    }
};

export const useManageColumnsDrawer = (
    columnType: columnType,
    defaultColumns: ManagedColumn[]
): UseDealDetailsDrawer => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [allColumns, setAllColumns] = useState<ManagedColumn[]>(
        getManagedColumnPreferences(columnType, defaultColumns)
    );
    const visibleColumns = useMemo(() => allColumns.filter((c) => c.visible), [allColumns]);
    const handleOpenManageColumnsDrawer = () => setIsDrawerOpen(true);
    const handleCloseManageColumnsDrawer = () => setIsDrawerOpen(false);
    const onChange = useCallback(
        (updatedColumns: ManagedColumn[]) => {
            setAllColumns(updatedColumns);
            saveManagedColumnPreferences(columnType, updatedColumns);
        },
        [columnType]
    );
    useEffect(() => {
        onChange(defaultColumns);
    }, [defaultColumns, onChange]);

    return {
        isDrawerOpen,
        allColumns,
        visibleColumns,
        handleOpenManageColumnsDrawer,
        handleCloseManageColumnsDrawer,
        onChange,
    };
};
