import { FC, Fragment, Key, ReactNode } from "react";
import { ExpandCollapse, TagsCollapsePanel, TagsCollapsePanelProps } from "@rubicon/antd-components";
import { Targeting } from "@app/core/services/console";
import {
    contentMetadataTitleToLabelValue,
    contentMetadataTvSeriesToLabelValue,
    getGeoTargetValue,
    getGeoTargetLabel,
    hasIdNameToLabelValue,
    inventoryToLabelValue,
    labelValuesToLabeledValues,
    segmentsToLabelValue,
    supplyTypesToLabelValue,
    minMaxDurationByOperation,
} from "../helpers";
import {
    TargetingDimensionTypeLabels,
    Conditions,
    Operations,
    AD_BREAK_VALUE_TO_LABEL,
    POD_SLOT_VALUE_TO_LABEL,
} from "../constants";
import { ReadOnlyTextAreaTargetingPanel, ReadOnlyTextAreaTargetingPanelProps } from "./ReadOnlyTextAreaTargetingPanel";
import {
    ReadOnlyMinDurationTargetingPanel,
    ReadOnlyMinDurationTargetingPanelProps,
} from "./ReadOnlyMinDurationTargetingPanel";
import { ReadOnlyCategoryPanel, ReadOnlyCategoryPanelProps } from "./ReadOnlyCategoryPanel";
import {
    ReadOnlyMaxDurationTargetingPanel,
    ReadOnlyMaxDurationTargetingPanelProps,
} from "./ReadOnlyMaxDurationTargetingPanel";
import { ReadOnlyPmpPanel, ReadOnlyPmpPanelProps } from "./ReadOnlyPmpPanel";
import { ReadOnlyInventoryTargetsPanel, ReadOnlyInventoryTargetsPanelProps } from "./ReadOnlyInventoryTargetsPanel";
import { ReadOnlyCategoryHeader } from "./ReadOnlyCategoryHeader";
import { DayPartTargetingPanel, DayPartTargetingPanelProps } from "./DayPartTargetingPanel";
import { ReadOnlySegmentRulePanel, ReadOnlySegmentRulePanelProps } from "./ReadOnlySegmentRulePanel";
import { TargetingStub } from "../types";
import {
    ReadOnlyCustomRulePanel,
    ReadOnlyCustomRulePanelProps,
} from "./ReadOnlyCustomRulePanel/ReadOnlyCustomRulePanel";

interface Props {
    targetingBlock: Targeting | TargetingStub;
    operation?: Operations;
}

const getReadOnlyExcludeTargetingPanel = <T extends { header: ReactNode; key?: Key }>(
    category: TargetingDimensionTypeLabels,
    condition: Conditions,
    operation: Operations | undefined,
    Component: FC<T>,
    props: Omit<T, "key" | "header">
) => {
    const key = `readOnlyNameTarget-${category}-${condition}-${operation}`;
    return {
        panel: (
            // Ignore ts error due to Component arg with generic FC type
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Component
                header={<ReadOnlyCategoryHeader category={category} condition={condition} operation={operation} />}
                key={key}
                {...props}
            />
        ),
        key,
    };
};

export const ReadOnlyExcludeTargetingCollapse: FC<Props> = ({ targetingBlock, operation }) => {
    const readOnlyExcludeTargetingCollapsePanels = [
        // Exclude Blocks
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Categories,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            { values: hasIdNameToLabelValue(targetingBlock.exclude?.categories || null) }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Sizes,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.sizes || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.OperatingSystems,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.operatingSystems || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Platforms,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.platforms || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.MimeTypes,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.mimeTypes || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ApiFrameworks,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.apiFrameworks || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.GeoTargets,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.geoTargets || []).map((v) => ({
                    label: getGeoTargetLabel(v),
                    value: getGeoTargetValue(v),
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyTextAreaTargetingPanelProps>(
            TargetingDimensionTypeLabels.SupplyDomainTargets,
            Conditions.Excludes,
            Operations.Any,
            ReadOnlyTextAreaTargetingPanel,
            {
                targets: targetingBlock.exclude?.supplyDomainTargets || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyTextAreaTargetingPanelProps>(
            TargetingDimensionTypeLabels.BundleIdTargets,
            Conditions.Excludes,
            Operations.Any,
            ReadOnlyTextAreaTargetingPanel,
            {
                targets: targetingBlock.exclude?.bundleIdTargets || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyMinDurationTargetingPanelProps>(
            TargetingDimensionTypeLabels.MinDurationTarget,
            Conditions.Excludes,
            operation ?? Operations.Any,
            ReadOnlyMinDurationTargetingPanel,
            {
                target: minMaxDurationByOperation(
                    targetingBlock.exclude?.minDurationTarget || [],
                    operation ?? Operations.Any,
                    "minDuration"
                ),
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyMaxDurationTargetingPanelProps>(
            TargetingDimensionTypeLabels.MaxDurationTarget,
            Conditions.Excludes,
            Operations.Any,
            ReadOnlyMaxDurationTargetingPanel,
            {
                target: minMaxDurationByOperation(
                    targetingBlock.exclude?.maxDurationTarget || [],
                    operation ?? Operations.Any,
                    "maxDuration"
                ),
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyCategoryPanelProps>(
            TargetingDimensionTypeLabels.Coppa,
            Conditions.Excludes,
            undefined,
            ReadOnlyCategoryPanel,
            {
                category: TargetingDimensionTypeLabels.Coppa,
                targets: targetingBlock.exclude?.coppa || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyCategoryPanelProps>(
            TargetingDimensionTypeLabels.Dnt,
            Conditions.Excludes,
            undefined,
            ReadOnlyCategoryPanel,
            {
                category: TargetingDimensionTypeLabels.Dnt,
                targets: targetingBlock.exclude?.dnt || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyCategoryPanelProps>(
            TargetingDimensionTypeLabels.LiveStream,
            Conditions.Excludes,
            undefined,
            ReadOnlyCategoryPanel,
            {
                category: TargetingDimensionTypeLabels.LiveStream,
                targets: targetingBlock.exclude?.liveStream || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyPmpPanelProps>(
            TargetingDimensionTypeLabels.Pmp,
            Conditions.Excludes,
            Operations.Any,
            ReadOnlyPmpPanel,
            {
                targets: targetingBlock.exclude?.pmpDealIdTargets || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.AdBreakPositionTargets,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.adBreakPositionTargets || []).map((value) => ({
                    label: AD_BREAK_VALUE_TO_LABEL[value] || value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.PodSlotPositionTargets,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.podSlotPositionTargets || []).map((value) => ({
                    label: POD_SLOT_VALUE_TO_LABEL[value] || value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlySegmentRulePanelProps>(
            TargetingDimensionTypeLabels.SegmentRules,
            Conditions.Excludes,
            undefined,
            ReadOnlySegmentRulePanel,
            {
                targets: targetingBlock.exclude?.segmentRules || null,
                segments: targetingBlock.exclude?.targetedSegments || null,
            }
        ),
        !targetingBlock.exclude?.segmentRules?.length &&
            getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
                TargetingDimensionTypeLabels.SegmentRules,
                Conditions.Excludes,
                Operations.Any,
                TagsCollapsePanel,
                {
                    values: segmentsToLabelValue(targetingBlock.exclude?.segments || null),
                }
            ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Bvod,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.oztamDemographics || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.CustomTargets,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.customTargets || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyInventoryTargetsPanelProps>(
            TargetingDimensionTypeLabels.Inventory,
            Conditions.Excludes,
            Operations.Any,
            ReadOnlyInventoryTargetsPanel,
            {
                targetsPublishers: inventoryToLabelValue(targetingBlock.exclude?.publishers || null),
                targetsAdUnits: inventoryToLabelValue(targetingBlock.exclude?.adUnits || null),
                targetsBrands: inventoryToLabelValue(targetingBlock.exclude?.brands || null),
                targetsSupply: inventoryToLabelValue(targetingBlock.exclude?.supply || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentCategories,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.contentCategories || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentChannels,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.contentChannels || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentLengths,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.exclude?.contentLengths || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentNetworks,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.contentNetworks || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentRatings,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.contentRatings || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentSeries,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: contentMetadataTvSeriesToLabelValue(targetingBlock.exclude?.contentSeries || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<ReadOnlyCustomRulePanelProps>(
            TargetingDimensionTypeLabels.CustomRules,
            Conditions.Excludes,
            undefined,
            ReadOnlyCustomRulePanel,
            {
                targets: targetingBlock.exclude?.customRules || null,
            }
        ),
        getReadOnlyExcludeTargetingPanel<DayPartTargetingPanelProps>(
            TargetingDimensionTypeLabels.DayPartTargets,
            Conditions.Excludes,
            Operations.Any,
            DayPartTargetingPanel,
            {
                values: targetingBlock.exclude?.dayPartTargets || [],
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Genres,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.genres || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.LabelValues,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: labelValuesToLabeledValues(targetingBlock.exclude?.labelValues || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Producers,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.exclude?.producers || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.SupplyTypes,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: supplyTypesToLabelValue(targetingBlock.exclude?.supplyTypes || null),
            }
        ),
        getReadOnlyExcludeTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.VideoIds,
            Conditions.Excludes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: contentMetadataTitleToLabelValue(targetingBlock.exclude?.videoIds || null),
            }
        ),
    ].filter(Boolean) as { panel: JSX.Element; key: string }[];

    return (
        <ExpandCollapse defaultActiveKey={readOnlyExcludeTargetingCollapsePanels.map(({ key }) => key)}>
            {readOnlyExcludeTargetingCollapsePanels.map(({ panel, key }) => (
                <Fragment key={key}>{panel}</Fragment>
            ))}
        </ExpandCollapse>
    );
};
