import { LabeledValue } from "antd/lib/select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DealAdSourceTransparencyExtendedUserId,
    DealDateFieldType,
    DealFormAdSourceValues,
    DealFormFieldType,
    DealFormMode,
    DealFormValues,
    DealStartEndDateFieldType,
    DealType,
    FreqCapping,
} from "@app/features/deals/DealForm/types";
import {
    CREATE_AD_SOURCE_TYPES_NEW,
    CREATE_DEAL_FORM_ITEMS_NAME,
    DEAL_TYPES,
    TRANSPARENCY_ALL_VALUE,
    TRANSPARENCY_SHARED_VALUE,
} from "@app/features/deals/constants";
import {
    DEAL_DEFAULT_ENFORCEMENT_ID,
    DEAL_DEFAULT_FREQUENCY_CAPPING_ID,
    DEFAULT_ACTIVE_STATUS_ID,
    DEFAULT_AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE_TYPE_ID,
    DEFAULT_AD_SOURCE_AUCTION_TYPE,
    DEFAULT_AD_SOURCE_AUTOSCALE,
    DEFAULT_AD_SOURCE_EXTEND_TIMEOUT,
    DEFAULT_AD_SOURCE_FLOOR_TYPE,
    DEFAULT_AD_SOURCE_PRIRITY_ID,
    DEFAULT_AD_SOURCE_RUNNING_STATUS_ID,
    DEFAULT_AD_SOURCE_SEND_FLOOR,
    DEFAULT_AD_SOURCE_STRICT_MODE,
    DEFAULT_CONFIGURE_AD_SOURCE,
    DEFAULT_CONSENT,
    DEFAULT_FIXED_PRICING_MODEL_ID,
    DEFAULT_USD_CURRENCY_ID,
    DEFAULT_UTC_TIME_ZONE_ID,
} from "@app/features/deals/DealForm/useDealForm";
import { GlobalIndustry } from "@app/core/components";
import {
    AdSource,
    DealListItem,
    FinanceDefault,
    IabCategory,
    InventoryDistributionGroup,
    LabelValue,
    SeatContentTransparencyRule,
    SeatTransparencyDefault,
    Targeting,
} from "@app/core/services/console";
import { getValidDurationValue, getValuesFromDeal } from "@app/features/deals/DealForm/dealFormReducerUtils";
import { getTransparencyValuesFromDefaults, getValuesFromDealAdSource } from "./dealAdSourceFormReducerUtils";
import { ISO_8601_FORMAT } from "@app/core/components/constants";
import { RootState } from "@app/core/store";
import {
    AdSourceAdvertiserDomainModeIds,
    AdSourceIabCategoryModeIds,
    AdSourceTypeIds,
    DefaultDemandAcquisitionCostModel,
    DefaultIndustriesMode,
} from "@app/features/seatAdSources/constants";
import moment from "moment-timezone";
import {
    shouldSyncCpmRate,
    shouldSyncCurrency,
    shouldSyncEndDate,
    shouldSyncStartDate,
    shouldSyncStatus,
    shouldSyncTimeZone,
} from "@app/features/syncedFields";
import { FormPacingDeliverySchedule } from "@app/features/seatAdSources/SeatAdSourcesForm/AdSourcesSections";

export interface DealFormState {
    values: DealFormValues;
    isInitialized: boolean;
}

const initialStateAdSource: DealFormAdSourceValues = {
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN]: "",
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]: TRANSPARENCY_ALL_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_AUDIENCE_LOCK]: false,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_OZTAM_CO_VIEWING]: false,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_BUNDLE_ID]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_CONTENT]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_IP]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_REF]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_NAME]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_PAGE]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_STORE_URL]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_USER_ID]: TRANSPARENCY_SHARED_VALUE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALWAYS_SEND_FLOOR]: DEFAULT_AD_SOURCE_SEND_FLOOR,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ASSIGNEE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUCTION_TYPE]: DEFAULT_AD_SOURCE_AUCTION_TYPE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUTOSCALE]: DEFAULT_AD_SOURCE_AUTOSCALE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE]: moment().format(ISO_8601_FORMAT),
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BUNDLE_ID_OVERRIDE]: "",
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CONTENT_TRANSPARENCY_RULE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CURRENCY]: DEFAULT_USD_CURRENCY_ID,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL]: DefaultDemandAcquisitionCostModel.id,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DESCRIPTION]: "",
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DOMAIN_NAME_OVERRIDE]: "",
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTEND_TIMEOUT]: DEFAULT_AD_SOURCE_EXTEND_TIMEOUT,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTERNAL_CONTACT_NUMBER]: "",
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE]: DEFAULT_AD_SOURCE_FLOOR_TYPE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORY_BLOCK_MODE]: AdSourceIabCategoryModeIds.BLOCK_USE_INVENTORY,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INDUSTRIES_MODE]: DefaultIndustriesMode.id,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCKED_INDUSTRIES]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INVENTORY_DISTRIBUTION_GROUPS]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_LABELS]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_LIVE_EVENTS]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]: AdSourceAdvertiserDomainModeIds.BLOCK_USE_INVENTORY,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION]: CREATE_AD_SOURCE_TYPES_NEW,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME]: "",
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_IMPS]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_FALLBACK]: 0,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED]: false,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULE_ENABLED]: false,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULES]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_IMPRESSION_CAP]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_REDISTRIBUTE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PIXELS]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PRIORITY]: DEFAULT_AD_SOURCE_PRIRITY_ID,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REGION]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_RUN_UNDER_INBOUND_FLOOR]: false,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_SALES_CONTACT]: null,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS]: DEFAULT_AD_SOURCE_RUNNING_STATUS_ID,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STRICT_MODE]: DEFAULT_AD_SOURCE_STRICT_MODE,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TARGETING]: [],
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TIME_ZONE]: DEFAULT_UTC_TIME_ZONE_ID,
    [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE]:
        DEFAULT_AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE_TYPE_ID,
};

export const initialState: DealFormState = {
    values: {
        [CREATE_DEAL_FORM_ITEMS_NAME.TYPE]: DEAL_TYPES.GUARANTEE,
        [CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]: DEFAULT_USD_CURRENCY_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_CURRENCY]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_TYPE]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.ENFORCEMENT]: DEAL_DEFAULT_ENFORCEMENT_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.FREQUENCY_CAPPING]: DEAL_DEFAULT_FREQUENCY_CAPPING_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.DEAL_FREQUENCY_CAPPINGS]: [],
        [CREATE_DEAL_FORM_ITEMS_NAME.DEMAND_TARGETING]: [],
        [CREATE_DEAL_FORM_ITEMS_NAME.SHARED_MARKEPLACES]: [],
        [CREATE_DEAL_FORM_ITEMS_NAME.SHARED_SEATS]: [],
        [CREATE_DEAL_FORM_ITEMS_NAME.DSP]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.ID]: "",
        [CREATE_DEAL_FORM_ITEMS_NAME.BUYER]: [],
        [CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_NAME]: "",
        [CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_EMAIL]: "",
        [CREATE_DEAL_FORM_ITEMS_NAME.NAME]: "",
        [CREATE_DEAL_FORM_ITEMS_NAME.ORIGIN]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.PRICE_MODEL]: DEFAULT_FIXED_PRICING_MODEL_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.PRICE_TYPE]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.RATE]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]: moment().format(ISO_8601_FORMAT),
        [CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]: null,
        [CREATE_DEAL_FORM_ITEMS_NAME.STATUS]: DEFAULT_ACTIVE_STATUS_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE]: DEFAULT_UTC_TIME_ZONE_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.CONFIGURE_AD_SOURCE]: DEFAULT_CONFIGURE_AD_SOURCE,
        [CREATE_DEAL_FORM_ITEMS_NAME.CONSENT]: DEFAULT_CONSENT,
        ...initialStateAdSource,
    },
    isInitialized: false,
};

const dealFormSlice = createSlice({
    name: "dealForm",
    initialState,
    reducers: {
        dealFormInputFieldChange: (
            state: DealFormState,
            action: PayloadAction<{ field: DealFormFieldType; value: string | number | null | undefined }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
        },
        dealFormRadioGroupChange: (
            state: DealFormState,
            action: PayloadAction<{ field: DealFormFieldType; value: string | number | boolean | null }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
        },
        dealFormCheckboxChange: (
            state: DealFormState,
            action: PayloadAction<{ field: DealFormFieldType; value: boolean }>
        ) => {
            state.values[action.payload.field] = !action.payload.value;
        },
        dealFormSelectFieldChange: (
            state: DealFormState,
            action: PayloadAction<{
                field: DealFormFieldType;
                value:
                    | LabeledValue
                    | LabeledValue[]
                    | IabCategory[]
                    | InventoryDistributionGroup[]
                    | GlobalIndustry[]
                    | number
                    | number[]
                    | string
                    | null;
            }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
        },
        dealFormStartEndDateFieldChange: (
            state: DealFormState,
            action: PayloadAction<{ field: DealStartEndDateFieldType; value: string | null }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
        },
        dealFormDateFieldChange: (
            state: DealFormState,
            action: PayloadAction<{ field: DealDateFieldType; value: moment.Moment | null }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
        },
        dealFormDealNameFieldChange: (
            state: DealFormState,
            action: PayloadAction<{ field: DealFormFieldType; value: string }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME] = action.payload.value;
        },
        dealFormDealNameFieldChangeEditMode: (
            state: DealFormState,
            action: PayloadAction<{ field: DealFormFieldType; value: string }>
        ) => {
            state.values[action.payload.field] = action.payload.value;
        },
        dealFormDealTypeFieldChange: (state: DealFormState, action: PayloadAction<DealType>) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE] = action.payload;
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION] = null;
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.PRICE_MODEL] = DEFAULT_FIXED_PRICING_MODEL_ID;
            if (action.payload === AdSourceTypeIds.AUCTION_PRICE) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE] = null;
            }
            if (action.payload === AdSourceTypeIds.PROGRAMMATIC_GUARANTEED) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION] = CREATE_AD_SOURCE_TYPES_NEW;
            }
        },
        dealFormDspFieldChange: (state: DealFormState, action: PayloadAction<number | null>) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.DSP] = action.payload;
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.BUYER] = [];
        },
        dealFormDurationFieldChange: (
            state: DealFormState,
            action: PayloadAction<{
                field:
                    | typeof CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MAX_DURATION
                    | typeof CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MIN_DURATION;
                value: string | null;
            }>
        ) => {
            state.values[action.payload.field] = getValidDurationValue(action.payload.value);
        },
        dealFormFrequencyCappingItemChange: (state: DealFormState, action: PayloadAction<FreqCapping>) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.DEAL_FREQUENCY_CAPPINGS] = [
                ...state.values.dealFreqCappings,
                action.payload,
            ];
        },
        dealFormFrequencyCappingItemRemove: (state: DealFormState, action: PayloadAction<{ id: string }>) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.DEAL_FREQUENCY_CAPPINGS] = state.values.dealFreqCappings.filter(
                (item: FreqCapping) => item.id !== action.payload.id
            );
        },
        dealFormAdSourceExtendedUserId: (
            state: DealFormState,
            action: PayloadAction<Array<DealAdSourceTransparencyExtendedUserId> | []>
        ) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES] = action.payload;
        },
        dealFormAdSourceLabels: (state: DealFormState, action: PayloadAction<Array<LabelValue> | null>) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_LABELS] = action.payload;
        },
        dealFormResetField: (state: DealFormState) => {
            state.values = initialState.values;
            state.isInitialized = false;
        },
        dealFormResetAdSourceFieldsWithDealDefaults: (state: DealFormState) => {
            state.values = {
                ...state.values,
                ...initialStateAdSource,
            };

            // The function is only called when we are creating a new Ad Source
            // If we start to call it else where we'll need to calculate this value
            const isOneToOne = true;

            const name = state.values[CREATE_DEAL_FORM_ITEMS_NAME.NAME];
            const adSourceTypeId = state.values[CREATE_DEAL_FORM_ITEMS_NAME.TYPE];
            const cpmRate = state.values[CREATE_DEAL_FORM_ITEMS_NAME.RATE];
            const currency = state.values[CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY];
            const startDate = state.values[CREATE_DEAL_FORM_ITEMS_NAME.START_DATE];
            const endDate = state.values[CREATE_DEAL_FORM_ITEMS_NAME.END_DATE];
            const status = state.values[CREATE_DEAL_FORM_ITEMS_NAME.STATUS];
            const timeZone = state.values[CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE];
            const priceModelId = state.values[CREATE_DEAL_FORM_ITEMS_NAME.PRICE_MODEL];
            const floorTypeId = state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE];

            if (name) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME] = name;
            }
            if (shouldSyncCpmRate(isOneToOne, cpmRate, adSourceTypeId, priceModelId, floorTypeId)) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE] = cpmRate ? Number(cpmRate) : null;
            }
            if (shouldSyncCurrency(isOneToOne, currency ? { id: currency } : null, adSourceTypeId)) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CURRENCY] = currency;
            }
            if (shouldSyncStartDate(isOneToOne, false, moment(startDate), adSourceTypeId, undefined)) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE] = startDate;
            }
            if (shouldSyncEndDate(isOneToOne, moment(endDate), adSourceTypeId)) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE] = endDate;
            }
            if (shouldSyncStatus(isOneToOne, status ? { id: status } : null)) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS] = status;
            }
            if (shouldSyncTimeZone(isOneToOne, false, timeZone ? { id: timeZone } : null, adSourceTypeId, undefined)) {
                state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TIME_ZONE] = timeZone;
            }
        },
        dealFormSetValues: (
            state: DealFormState,
            action: PayloadAction<{
                deal: DealListItem;
                mode: DealFormMode;
                adSource: AdSource | undefined;
                seatFinanceDefaults: FinanceDefault[] | undefined;
            }>
        ) => {
            const { deal, mode, adSource, seatFinanceDefaults } = action.payload;
            state.values = getValuesFromDeal(deal, mode, adSource, seatFinanceDefaults);
        },
        dealFormAdSourceSetValues: (
            state: DealFormState,
            action: PayloadAction<{
                adSource: AdSource | undefined;
                seatFinanceDefaults: FinanceDefault[] | undefined;
                isCopyingAdSource: boolean;
                mode: DealFormMode;
            }>
        ) => {
            state.values = {
                ...state.values,
                ...getValuesFromDealAdSource(
                    action.payload.adSource,
                    action.payload.seatFinanceDefaults,
                    action.payload.isCopyingAdSource,
                    action.payload.mode
                ),
            };
        },
        dealFormAdSourceSetTargeting: (
            state: DealFormState,
            action: PayloadAction<{ targetingBlocks: Targeting[] }>
        ) => {
            state.values = {
                ...state.values,
                [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TARGETING]: action.payload.targetingBlocks,
            };
        },
        dealFormSetDemandTargeting: (state: DealFormState, action: PayloadAction<{ targetingBlocks: Targeting[] }>) => {
            state.values = {
                ...state.values,
                [CREATE_DEAL_FORM_ITEMS_NAME.DEMAND_TARGETING]: action.payload.targetingBlocks,
            };
        },
        dealFormAdSourceSetDefaultTransparency: (
            state: DealFormState,
            action: PayloadAction<{ transparencyDefaults: SeatTransparencyDefault }>
        ) => {
            state.values = {
                ...state.values,
                ...getTransparencyValuesFromDefaults(action.payload.transparencyDefaults),
            };
        },
        dealFormAdSourceContentChange: (
            state: DealFormState,
            action: PayloadAction<{ value: string; transparencyRules: SeatContentTransparencyRule[] | undefined }>
        ) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_CONTENT] = action.payload.value;
            const transparencyRule =
                action.payload.transparencyRules?.find((rule) => rule.id === Number(action.payload.value)) || null;
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CONTENT_TRANSPARENCY_RULE] = transparencyRule;
        },
        dealFormPacingDeliverySchedulesChange: (
            state: DealFormState,
            action: PayloadAction<FormPacingDeliverySchedule[]>
        ) => {
            state.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DELIVERY_SCHEDULES] = action.payload;
        },
        dealFormSetAsInitialized: (state: DealFormState) => {
            state.isInitialized = true;
        },
    },
});

export const selectDealFormAdSourceContentLabelValue = (state: RootState): string | null => {
    const transparencyRule = state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CONTENT_TRANSPARENCY_RULE];
    if (transparencyRule) {
        return String(transparencyRule.id);
    }
    return state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_CONTENT];
};

export const {
    dealFormAdSourceContentChange,
    dealFormAdSourceExtendedUserId,
    dealFormAdSourceLabels,
    dealFormAdSourceSetDefaultTransparency,
    dealFormAdSourceSetTargeting,
    dealFormAdSourceSetValues,
    dealFormCheckboxChange,
    dealFormDealNameFieldChange,
    dealFormDealNameFieldChangeEditMode,
    dealFormDealTypeFieldChange,
    dealFormDspFieldChange,
    dealFormDurationFieldChange,
    dealFormFrequencyCappingItemChange,
    dealFormFrequencyCappingItemRemove,
    dealFormInputFieldChange,
    dealFormRadioGroupChange,
    dealFormResetAdSourceFieldsWithDealDefaults,
    dealFormResetField,
    dealFormSelectFieldChange,
    dealFormSetAsInitialized,
    dealFormSetValues,
    dealFormStartEndDateFieldChange,
    dealFormDateFieldChange,
    dealFormPacingDeliverySchedulesChange,
} = dealFormSlice.actions;
export default dealFormSlice.reducer;
