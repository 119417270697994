import { FC, useState } from "react";
import { Col, Radio, Row, Space, Typography } from "antd";
import { MetricsCard } from "@rubicon/antd-components";
import { NoDataAvailable } from "@app/core/components/LoadableComponent/NoDataAvailable";

type TimeFrame = "last24Hours" | "liveStats";

export const Overview: FC = () => {
    const [activeView, setActiveView] = useState<TimeFrame>("last24Hours");

    return (
        <Space direction="vertical" style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Space direction="vertical" style={{ display: "flex" }}>
                        <Typography.Text strong>Total Live Stats</Typography.Text>
                        <MetricsCard
                            items={[
                                { title: "Tries", value: "- -" },
                                { title: "Fills", value: "- -" },
                                { title: "Impressions", value: "- -" },
                                { title: "Errors", value: "- -" },
                                { title: "Fallbacks", value: "- -" },
                                { title: "Skips", value: "- -" },
                                { title: "Net Revenue", value: "- -" },
                            ]}
                        />
                    </Space>
                </Col>
                <Col span={18}>
                    <Space direction="vertical" style={{ display: "flex" }}>
                        <Radio.Group
                            options={[
                                { label: "Last 24 Hours", value: "last24Hours" },
                                { label: "Live Stats", value: "liveStats" },
                            ]}
                            onChange={({ target: { value } }) => setActiveView(value)}
                            optionType="button"
                            value={activeView}
                        />
                        <Typography.Text>{activeView} chart placeholder</Typography.Text>
                        <NoDataAvailable />
                    </Space>
                </Col>
            </Row>
        </Space>
    );
};
