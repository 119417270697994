import { useParams } from "react-router-dom";
import { LabelValue, useGetBrandInheritedLabelsQuery } from "@app/core/services";
import { useBrandChannelFormInstance } from "@app/features/inventory/HierarchyForms/BrandChannelForm/BrandChannelFormInstance";

export interface UseBrandChannelInheritedLabels {
    isInheritedLabelsShown: boolean;
    labels: LabelValue[];
}

export interface UseBrandChannelInheritedLabelsProps {
    labelType?: "seat" | "internal";
}

export const useBrandChannelInheritedLabels = ({
    labelType,
}: UseBrandChannelInheritedLabelsProps): UseBrandChannelInheritedLabels => {
    const { mode } = useBrandChannelFormInstance();
    const { brandId } = useParams<{ brandId: string }>();
    const isEditMode = mode === "edit";

    const { data = [] } = useGetBrandInheritedLabelsQuery(
        { brandId: brandId, includeMine: false, labelType: labelType! },
        { skip: !isEditMode || !brandId || !labelType }
    );
    return {
        labels: data,
        isInheritedLabelsShown: isEditMode,
    };
};
