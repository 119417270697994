import { FC, Fragment, Key, ReactNode } from "react";
import { ExpandCollapse, TagsCollapsePanel, TagsCollapsePanelProps } from "@rubicon/antd-components";
import { Audience, Targeting } from "@app/core/services/console";
import {
    contentMetadataTitleToLabelValue,
    contentMetadataTvSeriesToLabelValue,
    getGeoTargetLabel,
    getGeoTargetValue,
    hasIdNameToLabelValue,
    inventoryToLabelValue,
    labelValuesToLabeledValues,
    minMaxDurationByOperation,
    segmentsToLabelValue,
    supplyTypesToLabelValue,
} from "../helpers";
import {
    TargetingDimensionTypeLabels,
    Conditions,
    Operations,
    AD_BREAK_VALUE_TO_LABEL,
    POD_SLOT_VALUE_TO_LABEL,
} from "../constants";
import { ReadOnlyTextAreaTargetingPanel, ReadOnlyTextAreaTargetingPanelProps } from "./ReadOnlyTextAreaTargetingPanel";
import {
    ReadOnlyMinDurationTargetingPanel,
    ReadOnlyMinDurationTargetingPanelProps,
} from "./ReadOnlyMinDurationTargetingPanel";
import { ReadOnlyCategoryPanel, ReadOnlyCategoryPanelProps } from "./ReadOnlyCategoryPanel";
import {
    ReadOnlyMaxDurationTargetingPanel,
    ReadOnlyMaxDurationTargetingPanelProps,
} from "./ReadOnlyMaxDurationTargetingPanel";
import { ReadOnlyPmpPanel, ReadOnlyPmpPanelProps } from "./ReadOnlyPmpPanel";
import { ReadOnlyInventoryTargetsPanel, ReadOnlyInventoryTargetsPanelProps } from "./ReadOnlyInventoryTargetsPanel";
import { ReadOnlyCategoryHeader } from "./ReadOnlyCategoryHeader";
import { DayPartTargetingPanel, DayPartTargetingPanelProps } from "./DayPartTargetingPanel";
import { ReadOnlySegmentRulePanel, ReadOnlySegmentRulePanelProps } from "./ReadOnlySegmentRulePanel";
import { TargetingStub } from "../types";
import {
    ReadOnlyCustomRulePanel,
    ReadOnlyCustomRulePanelProps,
} from "./ReadOnlyCustomRulePanel/ReadOnlyCustomRulePanel";

interface Props {
    targetingBlock: Targeting | TargetingStub;
    operation?: Operations;
}

const getReadOnlyTargetingPanel = <T extends { header: ReactNode; key?: Key }>(
    category: TargetingDimensionTypeLabels,
    condition: Conditions,
    operation: Operations | undefined,
    Component: FC<T>,
    props: Omit<T, "key" | "header">
) => {
    const key = `readOnlyNameTarget-${category}-${condition}-${operation}`;
    return {
        panel: (
            // Ignore ts error due to Component arg with generic FC type
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Component
                header={<ReadOnlyCategoryHeader category={category} condition={condition} operation={operation} />}
                key={key}
                {...props}
            />
        ),
        key,
    };
};

export const ReadOnlyIncludeTargetingCollapse: FC<Props> = ({ targetingBlock, operation }) => {
    const readOnlyIncludeTargetingCollapsePanels = [
        // Include Blocks
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Audiences,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                // audiences value occurs to be [null] for some targeting, https://qa.vhfp.net/platform/resources/seats/reusableTargeting/199897
                // so filter the null value out before mapping
                values: (targetingBlock.include?.audiences || []).filter(Boolean).map((v: Audience) => ({
                    value: v.id,
                    label: `${v.name} ($${v.minPriceUSD} - $${v.maxPriceUSD})`,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Categories,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            { values: hasIdNameToLabelValue(targetingBlock.include?.categories || null) }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Sizes,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.sizes || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.OperatingSystems,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.operatingSystems || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Platforms,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.platforms || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.MimeTypes,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.mimeTypes || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ApiFrameworks,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.apiFrameworks || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.GeoTargets,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.geoTargets || []).map((v) => ({
                    label: getGeoTargetLabel(v),
                    value: getGeoTargetValue(v),
                })),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyTextAreaTargetingPanelProps>(
            TargetingDimensionTypeLabels.SupplyDomainTargets,
            Conditions.Includes,
            Operations.Any,
            ReadOnlyTextAreaTargetingPanel,
            {
                targets: targetingBlock.include?.supplyDomainTargets || [],
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.SupplyTypes,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: supplyTypesToLabelValue(targetingBlock.include?.supplyTypes || null),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyTextAreaTargetingPanelProps>(
            TargetingDimensionTypeLabels.BundleIdTargets,
            Conditions.Includes,
            Operations.Any,
            ReadOnlyTextAreaTargetingPanel,
            {
                targets: targetingBlock.include?.bundleIdTargets || [],
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyMinDurationTargetingPanelProps>(
            TargetingDimensionTypeLabels.MinDurationTarget,
            Conditions.Includes,
            operation ?? Operations.Any,
            ReadOnlyMinDurationTargetingPanel,
            {
                target: minMaxDurationByOperation(
                    targetingBlock.include?.minDurationTarget || [],
                    operation ?? Operations.Any,
                    "minDuration"
                ),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyMaxDurationTargetingPanelProps>(
            TargetingDimensionTypeLabels.MaxDurationTarget,
            Conditions.Includes,
            operation ?? Operations.Any,
            ReadOnlyMaxDurationTargetingPanel,
            {
                target: minMaxDurationByOperation(
                    targetingBlock.include?.maxDurationTarget || [],
                    operation ?? Operations.Any,
                    "maxDuration"
                ),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyCategoryPanelProps>(
            TargetingDimensionTypeLabels.Coppa,
            Conditions.Includes,
            undefined,
            ReadOnlyCategoryPanel,
            {
                category: TargetingDimensionTypeLabels.Coppa,
                targets: targetingBlock.include?.coppa || [],
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyCategoryPanelProps>(
            TargetingDimensionTypeLabels.Dnt,
            Conditions.Includes,
            undefined,
            ReadOnlyCategoryPanel,
            {
                category: TargetingDimensionTypeLabels.Dnt,
                targets: targetingBlock.include?.dnt || [],
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyCategoryPanelProps>(
            TargetingDimensionTypeLabels.LiveStream,
            Conditions.Includes,
            undefined,
            ReadOnlyCategoryPanel,
            {
                category: TargetingDimensionTypeLabels.LiveStream,
                targets: targetingBlock.include?.liveStream || [],
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyPmpPanelProps>(
            TargetingDimensionTypeLabels.Pmp,
            Conditions.Includes,
            Operations.Any,
            ReadOnlyPmpPanel,
            {
                targets: targetingBlock.include?.pmpDealIdTargets || [],
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.AdBreakPositionTargets,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.adBreakPositionTargets || []).map((value) => ({
                    label: AD_BREAK_VALUE_TO_LABEL[value] || value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.PodSlotPositionTargets,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.podSlotPositionTargets || []).map((value) => ({
                    label: POD_SLOT_VALUE_TO_LABEL[value] || value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlySegmentRulePanelProps>(
            TargetingDimensionTypeLabels.SegmentRules,
            Conditions.Includes,
            undefined,
            ReadOnlySegmentRulePanel,
            {
                targets: targetingBlock.include?.segmentRules || null,
                segments: targetingBlock.include?.targetedSegments || null,
            }
        ),
        !targetingBlock.include?.segmentRules?.length &&
            getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
                TargetingDimensionTypeLabels.SegmentRules,
                Conditions.Includes,
                Operations.Any,
                TagsCollapsePanel,
                {
                    values: segmentsToLabelValue(targetingBlock.include?.segments || null),
                }
            ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Bvod,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.oztamDemographics || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.CustomTargets,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.customTargets || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyInventoryTargetsPanelProps>(
            TargetingDimensionTypeLabels.Inventory,
            Conditions.Includes,
            Operations.Any,
            ReadOnlyInventoryTargetsPanel,
            {
                targetsPublishers: inventoryToLabelValue(targetingBlock.include?.publishers || null),
                targetsAdUnits: inventoryToLabelValue(targetingBlock.include?.adUnits || null),
                targetsBrands: inventoryToLabelValue(targetingBlock.include?.brands || null),
                targetsSupply: inventoryToLabelValue(targetingBlock.include?.supply || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentCategories,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.contentCategories || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentChannels,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.contentChannels || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentLengths,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: hasIdNameToLabelValue(targetingBlock.include?.contentLengths || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentNetworks,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.contentNetworks || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentRatings,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.contentRatings || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.ContentSeries,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: contentMetadataTvSeriesToLabelValue(targetingBlock.include?.contentSeries || null),
            }
        ),
        getReadOnlyTargetingPanel<ReadOnlyCustomRulePanelProps>(
            TargetingDimensionTypeLabels.CustomRules,
            Conditions.Includes,
            undefined,
            ReadOnlyCustomRulePanel,
            {
                targets: targetingBlock.include?.customRules || null,
            }
        ),
        getReadOnlyTargetingPanel<DayPartTargetingPanelProps>(
            TargetingDimensionTypeLabels.DayPartTargets,
            Conditions.Includes,
            Operations.Any,
            DayPartTargetingPanel,
            {
                values: targetingBlock.include?.dayPartTargets || [],
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Genres,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.genres || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.LabelValues,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: labelValuesToLabeledValues(targetingBlock.include?.labelValues || null),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.Producers,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: (targetingBlock.include?.producers || []).map((value) => ({
                    label: value,
                    value,
                })),
            }
        ),
        getReadOnlyTargetingPanel<TagsCollapsePanelProps>(
            TargetingDimensionTypeLabels.VideoIds,
            Conditions.Includes,
            Operations.Any,
            TagsCollapsePanel,
            {
                values: contentMetadataTitleToLabelValue(targetingBlock.include?.videoIds || null),
            }
        ),
    ].filter(Boolean) as { panel: JSX.Element; key: string }[];

    return (
        <ExpandCollapse defaultActiveKey={readOnlyIncludeTargetingCollapsePanels.map(({ key }) => key)}>
            {readOnlyIncludeTargetingCollapsePanels.map(({ panel, key }) => (
                <Fragment key={key}>{panel}</Fragment>
            ))}
        </ExpandCollapse>
    );
};
