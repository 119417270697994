import { css } from "@emotion/css";
import { Col, Collapse, Form, Grid, Row } from "antd";
import { FC } from "react";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { useUserAccess } from "@app/core/auth";
import { useParams } from "react-router-dom";
import { useGetSupplySeatQuery } from "@app/core/services/console";
import { useGetAdSourceCoreIndexTimeQuery } from "@app/core/services/console";
import { useGetAdSourceTargetingIndexTimeQuery } from "@app/core/services/console";
import { SeatAdSourceStatusTag } from "@app/features/seatAdSources/SeatAdSourceStatusTag";
import { SynthesizedAdSourceStatus } from "@app/core/services";
import { Dashes } from "@app/core/components";
import { format } from "@rubicon/utils";
import { brandCobalt } from "@rubicon/antd-components";
import { formatDateInUtc } from "@app/core/utils";

const UNSPECIFIED = "Unspecified";

const className = css`
    .ant-collapse-item > .ant-collapse-header {
        max-width: 125px;
        padding: 0;
        color: ${brandCobalt};
    }
`;

const classNameAdminOptions = css`
    margin-top: 0.7rem;
`;
interface Props {
    id: number;
    name: string;
    description: string | null;
    type: string;
    priority: number;
    udePriority: string | null;
    synthesizedStatus: SynthesizedAdSourceStatus;
    bookingStartDate: string;
    bookingEndDate: string | null;
    extendedTimeOutValue: boolean;
    lastUpdated: string | null;
    datedCreated: string;
    lastIndexed: string | null;
    assignee?: string | null;
    region?: string | null;
    costModel?: string | null;
    allowAutoscale: boolean;
    salesContact?: string | null;
    externalContractId?: string | null;
    costValuePercent?: number | null;
    costValueFixed?: number | null;
}
export const AdSourceGeneral: FC<Props> = ({
    id,
    name,
    description,
    type,
    priority,
    udePriority,
    synthesizedStatus,
    extendedTimeOutValue,
    lastUpdated,
    datedCreated,
    assignee,
    costValuePercent,
    costValueFixed,
    allowAutoscale,
    costModel,
    region,
    salesContact,
    externalContractId,
}) => {
    const { hasInternalAccess, isTremorUser } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const { data: supplySeat } = useGetSupplySeatQuery(Number(seatId));
    const { data: adSourcesCore } = useGetAdSourceCoreIndexTimeQuery(Number(id));
    const { data: adSourcesTargeting } = useGetAdSourceTargetingIndexTimeQuery(Number(id));
    return (
        <>
            <ResponsiveLayout
                name={
                    <Form.Item style={{ wordWrap: "break-word" }} label="Name">
                        {name}
                    </Form.Item>
                }
                status={
                    <Form.Item label="Status">
                        <SeatAdSourceStatusTag synthesizedStatus={synthesizedStatus} />
                    </Form.Item>
                }
                description={
                    <>
                        {Boolean(description?.length) && (
                            <Form.Item style={{ wordWrap: "break-word" }} label="Description">
                                {description}
                            </Form.Item>
                        )}
                    </>
                }
                leftCol={
                    <>
                        <Form.Item label="Type">{type}</Form.Item>
                        <Form.Item label="Priority">{priority}</Form.Item>
                        <Form.Item label="Extend Timeout Value">{format.asYesOrNo(extendedTimeOutValue)}</Form.Item>
                        <Form.Item label="Dated Created (UTC)">
                            {formatDateInUtc(datedCreated, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)}
                        </Form.Item>
                        <Form.Item label="External Contract Number">{externalContractId || <Dashes />}</Form.Item>
                        <Form.Item label="Sales Contact">
                            <Dashes value={salesContact} />
                        </Form.Item>
                    </>
                }
                rightCol={
                    <>
                        <Form.Item label="Assignee">{assignee ? assignee : UNSPECIFIED}</Form.Item>
                        <Form.Item label="UD Priority">{udePriority ? udePriority : UNSPECIFIED}</Form.Item>
                        <Form.Item label="Last Updated (UTC)">
                            {formatDateInUtc(lastUpdated, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)}
                        </Form.Item>
                        {hasInternalAccess && (
                            <>
                                <Form.Item label="Last Indexed - Supply (UTC)">
                                    {supplySeat?.lastIndexed ? (
                                        formatDateInUtc(supplySeat?.lastIndexed, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)
                                    ) : (
                                        <Dashes />
                                    )}
                                </Form.Item>
                            </>
                        )}
                        <Form.Item label="Last Indexed - Core (UTC)">
                            {adSourcesCore?.lastIndexed ? (
                                formatDateInUtc(adSourcesCore?.lastIndexed, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)
                            ) : (
                                <Dashes />
                            )}
                        </Form.Item>
                        {hasInternalAccess && (
                            <>
                                <Form.Item label="Last Indexed - Targeting (UTC)">
                                    {adSourcesTargeting?.lastIndexed ? (
                                        formatDateInUtc(
                                            adSourcesTargeting?.lastIndexed,
                                            MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED
                                        )
                                    ) : (
                                        <Dashes />
                                    )}
                                </Form.Item>
                            </>
                        )}
                    </>
                }
            />

            {isTremorUser && (
                <Collapse expandIconPosition="end" className={className} ghost>
                    <Collapse.Panel key={1} header="Admin Options">
                        <Row className={classNameAdminOptions}>
                            <Col xs={12} xxl={8}>
                                <Form.Item label="Cost Model">
                                    <Dashes value={costModel} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} xxl={8}>
                                <Form.Item label="Cost">
                                    {(costValuePercent ?? (costValueFixed || 0) / 1000) || <Dashes />}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} xxl={8}>
                                <Form.Item label="Allow Autoscale">{format.asYesOrNo(allowAutoscale)}</Form.Item>
                            </Col>
                            <Col xs={12} xxl={8}>
                                <Form.Item label="Region">
                                    <Dashes value={region} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            )}
        </>
    );
};

const ResponsiveLayout = ({ name, status, description, leftCol, rightCol }) => {
    const { xl } = Grid.useBreakpoint();

    if (!xl) {
        return (
            <Row gutter={16}>
                <Col span={12}>{name}</Col>
                <Col span={12}>{status}</Col>
                <Col span={24}>{description}</Col>
                <Col span={12}>{leftCol}</Col>
                <Col span={12}>{rightCol}</Col>
            </Row>
        );
    }

    return (
        <Row gutter={16}>
            <Col span={8}>
                {name}
                {leftCol}
            </Col>
            <Col span={8}>
                {status}
                {rightCol}
            </Col>
            <Col span={8}>{description}</Col>
        </Row>
    );
};
