import { useMemo } from "react";
import {
    LabelValue,
    useGetBrandInheritedLabelsQuery,
    useGetAdUnitInheritedLabelsQuery,
    useGetSupplyInheritedLabelsQuery,
    useGetPublisherInheritedLabelsQuery,
} from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import {
    InventoryDetailsDrawerType,
    selectInventoryDetailsDrawerType,
} from "@app/features/inventory/DetailsDrawer/reducer";

interface UseInheritedLabels {
    inheritedLabels: LabelValue[];
}

interface UseInheritedLabelsProps {
    id: number;
    labelType: "seat" | "internal";
}

export const useInheritedLabels = ({ labelType, id }: UseInheritedLabelsProps): UseInheritedLabels => {
    const detailsDrawerType = useAppSelector(selectInventoryDetailsDrawerType);

    const isPublisherChannelDrawerType: boolean =
        detailsDrawerType === InventoryDetailsDrawerType.PUBLISHER ||
        detailsDrawerType === InventoryDetailsDrawerType.CHANNEL;

    const isBrandChannelDrawerType: boolean =
        detailsDrawerType === InventoryDetailsDrawerType.BRAND ||
        detailsDrawerType === InventoryDetailsDrawerType.BRAND_CHANNEL;

    const { data: publisherLabels } = useGetPublisherInheritedLabelsQuery(
        {
            publisherId: id,
            includeMine: false,
            labelType: labelType,
        },
        { skip: !isPublisherChannelDrawerType || !labelType }
    );
    const { data: brandLabels } = useGetBrandInheritedLabelsQuery(
        {
            brandId: id,
            includeMine: false,
            labelType: labelType,
        },
        { skip: !isBrandChannelDrawerType || !labelType }
    );
    const { data: supplyLables } = useGetSupplyInheritedLabelsQuery(
        {
            supplyId: id,
            includeMine: false,
            labelType: labelType,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.SUPPLY || !labelType }
    );
    const { data: adUnitLabels } = useGetAdUnitInheritedLabelsQuery(
        {
            adUnitId: id,
            includeMine: false,
            labelType: labelType,
        },
        { skip: detailsDrawerType !== InventoryDetailsDrawerType.AD_UNIT || !labelType }
    );

    const inheritedLabels = useMemo(
        () => publisherLabels || brandLabels || supplyLables || adUnitLabels || [],
        [publisherLabels, brandLabels, supplyLables, adUnitLabels]
    );

    return {
        inheritedLabels,
    };
};
