import { Card, Col, Form, Row, Collapse } from "antd";
import { FC, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "@rubicon/utils";
import { SeatAdSourcesType, useGetTransparencyDefaultsQuery } from "@app/core/services";
import { useSeatAuthContext } from "@app/core/auth/useSeatAuthContext";
import { AdSourceFormsContext, AdSourcesForm } from "../..";
import {
    mainStepSectionClassList,
    AD_SOURCE_SECTIONS,
    cardBodyStyle,
    AD_SOURCE_SECTIONS_NAME,
    COL_SIZES,
    GUTTER,
    AD_SOURCE_FIELDS,
    TransparencyEligibleAdSourceTypeIds,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import {
    AllowAudienceLockRadio,
    BundleIdOverride,
    BundleIdRadio,
    BvodConnectDemographicRadio,
    BvodConnectRadio,
    ContentSelect,
    DomainNameOverrideInput,
    DomainNameRadio,
    ExtendedUserIdList,
    FullIpAddress,
    IncludedExtendedIdsRadio,
    ReferUrlRadio,
    StoreUrlRadio,
    SupplyNameRadio,
    SupplyPageRadio,
    UserIdRadio,
    OztamCoViewingRadio,
} from "./Fields";
import { TRANSPARENCY_MASKED_VALUE } from "@app/features/deals/constants";
import { useUserAccess } from "@app/core/auth";

const { SHARED, HIDDEN } = format.constants;

const COL_SIZE_EXTENSION = { md: 12, lg: 12, xl: 12, xxl: 12 };

export const TransparencySection: FC = () => {
    const { context } = useSeatAuthContext();
    const isAudienceLockEnabled = Boolean(context?.audienceLockType.id === 3);
    const { isOztamEnabled } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();
    const adSourceForm = Form.useFormInstance<AdSourcesForm>();
    const { forms } = useContext(AdSourceFormsContext);
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeId = adSourceType?.id;
    const isVisibleByAdSourceType = TransparencyEligibleAdSourceTypeIds.has(adSourceTypeId);
    const { data: defaults } = useGetTransparencyDefaultsQuery(Number(seatId), { skip: !isVisibleByAdSourceType });
    const defaultsForCurrentAdSourceType = defaults?.find(
        (defaultForAdSourceType) => defaultForAdSourceType.adSourceType.name === adSourceType?.name
    );
    useEffect(() => {
        if (defaultsForCurrentAdSourceType && forms.adSourceFormMode === "create") {
            adSourceForm.setFieldsValue({
                [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.SUPPLY_NAME.name],
                [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.DOMAIN_NAME.name],
                [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.SUPPLY_PAGE.name],
                [AD_SOURCE_FIELDS.REFER_URL.name]: defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.REFER_URL.name],
                [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]:
                    defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name],
                [AD_SOURCE_FIELDS.BUNDLE_ID.name]: defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.BUNDLE_ID.name],
                [AD_SOURCE_FIELDS.STORE_URL.name]: defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.STORE_URL.name],
                [AD_SOURCE_FIELDS.USER_ID.name]: defaultsForCurrentAdSourceType.maskUserId
                    ? TRANSPARENCY_MASKED_VALUE
                    : defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.USER_ID.name],
                [AD_SOURCE_FIELDS.CONTENT.name]: defaultsForCurrentAdSourceType.contentTransparencyRule
                    ? defaultsForCurrentAdSourceType.contentTransparencyRule
                    : defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.CONTENT.name]
                    ? { name: SHARED, value: true }
                    : { name: HIDDEN, value: false },
                [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]:
                    defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name] ||
                    (defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.EXTENDED_USER_ID.name].length > 0
                        ? "specific"
                        : false),
                [AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]: defaultsForCurrentAdSourceType[
                    AD_SOURCE_FIELDS.EXTENDED_USER_ID.name
                ]
                    ? defaultsForCurrentAdSourceType[AD_SOURCE_FIELDS.EXTENDED_USER_ID.name].map(
                          ({ extendedId, network }) => ({
                              extendedId: {
                                  id: extendedId.id,
                                  name: extendedId.name,
                                  code: extendedId.code,
                              },
                              network: {
                                  id: network?.id,
                                  name: network?.name,
                              },
                          })
                      )
                    : [],
                [AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.name]: false,
            });
        }
    }, [adSourceForm, defaultsForCurrentAdSourceType, forms.adSourceFormMode]);

    if (!isVisibleByAdSourceType && !isAudienceLockEnabled) {
        return null;
    }

    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: AD_SOURCE_SECTIONS_NAME.TRANSPARENCY,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.TRANSPARENCY]}
                            section={AD_SOURCE_SECTIONS.TRANSPARENCY}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            {isVisibleByAdSourceType && (
                                <>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="supply-app-name">
                                            <SupplyNameRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="domain-name">
                                            <DomainNameRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="domain-override-input">
                                            <DomainNameOverrideInput />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="supply">
                                            <SupplyPageRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="refer-url">
                                            <ReferUrlRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="full-ip-address">
                                            <FullIpAddress />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="bundle-id">
                                            <BundleIdRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="bundle-override">
                                            <BundleIdOverride />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="store-url">
                                            <StoreUrlRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="user-id">
                                            <UserIdRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="bvod-connect">
                                            <BvodConnectRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col
                                            {...COL_SIZES}
                                            {...COL_SIZE_EXTENSION}
                                            data-sdet="bvod-connect-demographic"
                                        >
                                            <BvodConnectDemographicRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="content-select">
                                            <ContentSelect />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="include-extended-ids">
                                            <IncludedExtendedIdsRadio />
                                        </Col>
                                    </Row>
                                    <Row gutter={GUTTER}>
                                        <Col xs={18} sm={16} data-sdet="extended-user-id">
                                            <ExtendedUserIdList />
                                        </Col>
                                    </Row>
                                    {isOztamEnabled && (
                                        <Row gutter={GUTTER}>
                                            <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="oztam-co-viewing">
                                                <OztamCoViewingRadio />
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                            {isAudienceLockEnabled && (
                                <Row gutter={GUTTER}>
                                    <Col {...COL_SIZES} {...COL_SIZE_EXTENSION} data-sdet="allow-audience-lock">
                                        <AllowAudienceLockRadio />
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    ),
                },
            ]}
        />
    );
};
