import { useCommunicatorSession } from "@rubicon/iframe-communicator/react";

export const useIframeChange = () => {
    const session = useCommunicatorSession();

    const handleChangeApp = (appRoute: string, data: unknown) => {
        session?.emitEvent("appchange", { appRoute, data });
    };
    return { handleChangeApp };
};
