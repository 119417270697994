import { useParams } from "react-router-dom";
import { LabelValue, useGetPublisherInheritedLabelsQuery } from "@app/core/services";
import { usePublisherChannelFormInstance } from "@app/features/inventory/HierarchyForms/PublisherChannelForm/PublisherChannelFormInstance";

export interface usePublisherChannelInheritedLabels {
    isInheritedLabelsShown: boolean;
    labels: LabelValue[];
}

export interface usePublisherChannelInheritedLabelsProps {
    labelType: "seat" | "internal";
}

export const usePublisherChannelInheritedLabels = ({
    labelType,
}: usePublisherChannelInheritedLabelsProps): usePublisherChannelInheritedLabels => {
    const { mode } = usePublisherChannelFormInstance();
    const { channelId } = useParams<{ channelId: string }>();
    const isEditMode = mode === "edit";

    const { data = [] } = useGetPublisherInheritedLabelsQuery(
        { publisherId: channelId, includeMine: false, labelType: labelType },
        { skip: !isEditMode || !channelId }
    );
    return {
        labels: data,
        isInheritedLabelsShown: isEditMode,
    };
};
